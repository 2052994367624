<template>
    <div id="header-menu-bar">

        <!-- loading categories -->
        <div v-if="$store.state.loadingCategories">
            <span class="icon is-large  has-text-primary">
                <i class="fas fa-2x fa-spinner fa-pulse"></i>
            </span>
        </div>

        <!-- categories/menu -->
        <div v-else-if="$store.state.categories" id="categories-container">

            <!-- menu categories -->
            <div id="scroll-div-container">
                <div id="scroll-div">
                    
                    <!-- category container -->
                    <div

                        v-for="category in $store.state.categories"
                        :key="category.category_id"
                        class="category-item is-flex"
                    >
                        <div class="is-flex">
                        <!-- <div class="is-flex" v-if="$store.getters.isOwner"> -->
                            <!-- category name -->
                            <!-- <a
                                @click="$store.dispatch('fetchProducts', category.category_id)"
                                :id="'category_' + category.category_id"
                                class="menu-item"
                                :class="[category.category_id === $store.state.selectedCategory.category_id ? 'has-text-primary has-border-bottom' : 'has-text-grey']"
                            >
                                {{category.category_name}}
                            </a> -->


                            <router-link
                                :to="{name: 'categorie', params: { categoryUrl:category.category_url }}"
                                :id="'category_' + category.category_id"
                                class="menu-item"
                                :class="[category.category_id === $store.state.selectedCategory.category_id ? 'has-text-primary has-border-bottom' : 'has-text-grey']"
                            >
                                {{category.category_name}}
                            </router-link>

                            <!-- <router-link :to="{name: 'categorie', params: { categoryUrl:category.category_url, categoryId:category.category_id }}">{{category.category_name}}</router-link> | -->

                            <!-- <router-link :to="`/categorie/${category.category_name}`">{{category.category_name}}</router-link> |  -->


                        </div>
                    </div>
                </div>
                
                <!-- scroll left btn -->
                <span @click="scrollLeft()" class="scroll-buttons is-hidden" style="display: flex; position: absolute; left: 0;">
                    <span class="icon has-text-primary arrow-icons">
                        <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="span-gradient" style="background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);">
                    </span>
                </span>

                <!-- scroll right btn -->
                <span @click="scrollRight()" class="scroll-buttons is-hidden" style="display: flex; position: absolute; right: 0;">
                    <span class="span-gradient" style="background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);">
                    </span>
                    <span class="icon has-text-primary arrow-icons">
                        <i class="fas fa-chevron-right"></i>
                    </span>
                </span>
            </div>
        </div>
        <!-- <ModalConfirm/> -->
    </div>
</template>

<script>
import axios from 'axios';
// import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
    name: 'HeaderMenuBar',
    components: {
        // ModalConfirm
    },
    data() {
        return {

        }
    },
    methods: {
        scrollLeft() {
            document.getElementById('scroll-div').scrollLeft -= 130;
        },
        scrollRight() {
            document.getElementById('scroll-div').scrollLeft += 130;
        },
        showScrollButtons() {
            if(document.getElementById("scroll-div")) {
                let scrollDiv = document.getElementById("scroll-div");
                let scrollButtons = document.getElementsByClassName("scroll-buttons");
                if (scrollDiv.scrollWidth > scrollDiv.offsetWidth) {
                    scrollButtons[0].classList.remove('is-hidden');
                    scrollButtons[1].classList.remove('is-hidden');
                }
            }
        },
        scrollEnd() {
            if(document.getElementById("scroll-div")) {
                let scrollDiv = document.getElementById("scroll-div");
                scrollDiv.scrollLeft += 2000;
            }
        },
        showTab(tab) {
            this.tabActiv = tab;
        }

    },
    mounted() {
        if(this.$store.state.categories) {
            this.showScrollButtons();
        }
    },
    updated() {
        if(this.$store.state.categories) {
            this.showScrollButtons();
        }        
    }
    
}
</script>

<style scoped>
    #categories-container {
        display: flex;
        justify-content: center;
        width: 100%;
        border-top: 1px solid #EEE;
    }
    #scroll-div-container {
        padding: 0;
        position: relative;
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
    }
    #scroll-div {
        padding: 2px 15px !important;
        margin: 0 20px;
        display: flex;
        overflow-y:auto;
        white-space: nowrap;
        scroll-behavior: smooth;
    }
    .arrow-icons {
        width: 1.5rem !important;
        height: 2.8rem;
        background-color: #FFF;
    }
    .span-gradient {
        height:2.8rem;
        width: 1rem;
    }
    .scroll-buttons {
        cursor: pointer;
    }

    #header-menu-bar {
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu-item {
        padding: 10px 15px !important;
        display: inline-block;
        white-space:nowrap;
    }

    @media only screen and (min-width: 1024px) {
        /* Hide scrollbar for Chrome, Safari and Opera */
        #scroll-div-container::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE and Edge */
        #scroll-div-container {
            -ms-overflow-style: none;
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        #scroll-div::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE and Edge */
        #scroll-div {
            -ms-overflow-style: none;
        }
    }
</style>