<template>
  <div id="header-logo-bar">
    <!-- header left -->
    <div class="column">
      <!-- info -->
      <a @click="$router.push('/info')" class="info-icon is-hidden-tablet">
        <span class="icon">
          <i
            class="fas fa-shipping-fast is-size-5 is-size-5-touch has-text-primary"
          ></i>
        </span>
      </a>

      <!-- program livrari -->
      <div
        @click="$router.push('/info')"
        v-if="
          $store.state.company.program_livrari ||
            $store.state.company.localitati_livrare
        "
        class="is-hidden-mobile is-pointer has-text-grey-light has-text-weight-light has-text-centered is-size-6 is-size-5-fullhd"
        style="line-height: 22px; height: 100%;"
        title="Info comenzi & livrare"
      >
        <h1 v-if="$store.state.company.localitati_livrare">
          Livrare în {{ $store.state.company.localitati_livrare }}
        </h1>
        <div
          class="is-size-6 has-text-weight-normal"
          v-if="$store.state.company.program_livrari"
        >
          {{ $store.state.company.program_livrari }}
        </div>
      </div>
    </div>

    <!-- logo -->
    <div
      @click="$router.push('/info')"
      class="column is-narrow is-size-4 is-size-3-tablet is-size-2-fullhd has-text-primary has-text-weight-light has-text-centered is-pointer"
      style="white-space: nowrap"
    >
      {{ $store.state.company.company }}
      <div
        v-if="$store.state.company.nu_primim_comenzi === '1'"
        class="is-size-6 has-text-danger has-text-weight-bold"
      >
        MOMENTAN, NU PRIMIM COMENZI!
      </div>
    </div>

    <!-- header right -->
    <div class="column">
      <!-- phone -->
      <div
        v-if="
          $store.state.company.phone ||
            $store.state.company.numerar === '1' ||
            $store.state.company.card === '1'
        "
        class="is-hidden-mobile has-text-grey-light has-text-centered has-text-weight-light is-size-6 is-size-5-fullhd"
        style="line-height: 22px; height: 100%;"
      >
        <div>
          <!-- phone -->
          <div v-if="$store.state.company.phone">
            <a
              :href="'tel:' + $store.state.company.phone"
              class="has-text-grey-light"
              >Telefon livrări:
              <span class="has-text-weight-normal">{{
                $store.state.company.phone | phoneNumber
              }}</span></a
            >
          </div>
          <!-- plata -->
          <div class="is-size-6 has-text-weight-normal">
            Plata
            <!-- numerar -->
            <span v-if="$store.state.company.numerar === '1'">numerar</span>
            <!-- slash daca am numerar si card -->
            <span
              v-if="
                $store.state.company.numerar === '1' &&
                  $store.state.company.card === '1'
              "
              >/</span
            >
            <!-- card -->
            <span v-if="$store.state.company.card === '1'">card</span>
            <!-- (la livrare) daca am plata numerar sau card -->
            <span
              v-if="
                $store.state.company.numerar === '1' ||
                  $store.state.company.card === '1'
              "
            >
              (la livrare)</span
            >
            <span
              v-if="
                ($store.state.company.numerar === '1' ||
                  $store.state.company.card === '1') &&
                  $store.state.company.transfer === '1'
              "
              >,
            </span>
            <!-- transfer bancar -->
            <span v-if="$store.state.company.transfer === '1'">
              transfer bancar</span
            >
          </div>
        </div>
      </div>

      <!-- btn (icon) vizitator -->
      <a
        v-if="!$store.getters.isOwner"
        @click="$router.push('/user')"
        class="user-icon is-hidden-touch"
      >
        <span class="icon">
          <i class="far fa-user is-size-5 is-size-5-touch has-text-primary"></i>
        </span>
      </a>

      <!-- mobile only user icon -->
      <a @click="$router.push('/user')" class="user-icon is-hidden-desktop">
        <span class="icon">
          <i class="far fa-user is-size-5 is-size-5-touch has-text-primary"></i>
        </span>
      </a>
    </div>
    <!-- <ModalInfo/> -->
    <!-- <ModalUser/> -->
  </div>
</template>

<script>
// import ModalInfo from '@/components/ModalInfo.vue';
// import ModalUser from '@/components/ModalUser.vue';

export default {
  name: "HeaderLogoBar",
  components: {
    // ModalInfo,
    // ModalUser
  },
  filters: {
    phoneNumber: (string) =>
      `${string.substr(0, 4)} ${string.substr(4, 3)} ${string.substr(7, 3)}`,
  },
};
</script>

<style scoped>
.column {
  padding: 0;
  position: relative;
}
#header-logo-bar {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.user-icon {
  position: absolute;
  top: -12px;
  right: 0;
}
.info-icon {
  position: absolute;
  top: -12px;
  left: 0;
}
@media only screen and (min-width: 1024px) {
  #header-logo-bar {
    justify-content: center;
  }
}
@media only screen and (min-width: 769px) {
  .user-icon {
    top: 20%;
    /* top: 10px; */
    right: 20px;
  }
  .info-icon {
    top: 20%;
    left: 20px;
  }
}
</style>
