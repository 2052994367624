import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
// const apiUrl = "http://192.168.1.115/livrari.eu/api/";

Vue.use(Vuex)

export default new Vuex.Store({

	state: {
		// appUrl: process.env.VUE_APP_URL,
		// apiUrl: process.env.VUE_APP_URL + '/api/',
		appUrl: '',
		apiUrl: '',
		loadingFirstData: false,
		loadingUser: false,
		user: '',
		userUrl: '',
		userToken: '',
		authenticated: true, // stay in admin & show login form

		loadingCompany: false,
		company: '',
		wrongUrl: '',

		showProducts: true,
		// showModalLogin: false,
		// showModalInfo: false,
		// showClientOrdersModal: false,
		pastOrders: '',
		
		optionsActionsResult: '',
		productsActionsResult: '',

		modalConfirm: '', // opened?, type, msg
		modalResponseText: '', // this.SET_ERROR_MODAL_TEXT({type: 'error', text: 'text'});

		// showModalOrderData: false,
		loadingModalOrderData: false,
		modalOrderData: '',
		
		loadingCategories: false,
		categories: [],
		selectedCategory: '',
		// addCategory: false,
		// showBtnsEditCategories: false,
		// editCategory: '', // id, name

		loadingProducts: false,
		products: [],

		loadingProduct: false,
		product: '',
		modalAdminProduct: false,
		editProductId: '',
		// editProductModal: false,

		loadingOrders: false,
		orders: '',
		ordersInState: 0,
		ordersOnPage: 10,
		ordersCurrentPage: 0,
		ordersNrPages: 1, // Math.ceil(ordersInState/ordersOnPage)
		newOrders: 0,
		selectedOrderState: 1,

		// settingsModal: false,
		settingsColumn: false,


		modifyProduct: false,
		productModal: false,
		customProduct: {
			product_id: '',
			product_name: '',
			productPrice: '',
			productPriceName: '',
			qty: 1,
			topping: [],
			options: [],
			customProductPrice: '',
			priceTotal: '',
			id: ''
		},

		toppings: [], // topping
		options: [],

		cart: [],
		showCart: true,
		// cartModalOpened: false,

		// modalSendOrderOpened: false,

		// modalMenuMobile: false,
		scrollMenuIntoView: true,
		acceptCookies: false

		// status: '',
		// token: localStorage.getItem('userToken') || '',
		// user: {}

	},
	mutations, // = sychronous functions for changing state e.g. add, edit, delete
	actions, // = methods asynchronous functions that can call one or more mutation
	modules: {
	},
	getters
})
