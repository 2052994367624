<template>
    <!-- <div class="has-background-danger is-flex" style="justify-content: center;"> -->
        <div class="has-background-danger is-flex has-text-white is-size-6 is-size-6-tablet" id="admin-btns-container">

            <router-link :to="'/comenzi'">COMENZI</router-link>
            <!-- nr comenzi noi -->
            <span v-if="$store.state.newOrders" class="is-size-7 has-text-weight-light" style="margin-top:1px;">({{$store.state.newOrders}})</span>

            <span>&bull;</span>

            <!-- navigate to store from 'COMENZI' -->
            <a v-if="$route.path === '/comenzi'" @click="$store.dispatch('firstLoad')">
                <span class="is-hidden-mobile">GESTIONARE </span>CATEGORII/PRODUSE
            </a>

            <!-- new account, first category -->
            <a v-else-if="$store.state.categories === null" @click="moveToAddCategory()" class="underlined">
                ADAUGĂ CATEGORIE
            </a>

            <!-- open modal gestiune magazin/categorii/produse -->
            <a v-else @click="$router.push('/gestiune')" class="underlined">
                <span class="is-hidden-mobile">GESTIONARE </span>CATEGORII/PRODUSE
            </a>

            <!-- btn contul tau -->
            <span class="is-hidden-touch">&bull;</span> 
            <a @click="$router.push('/user')" class="is-hidden-touch">
                CONTUL TĂU
            </a> 

            <span>&bull;</span> 

            <!-- logout -->
            <a @click="iesire()">IEȘIRE</a>

        </div>

    <!-- </div> -->
</template>

<script>
import axios from 'axios';

export default {
    name: 'HeaderAdminBar',
    data() {
        return {


        }
    },
    methods: {

        moveToAddCategory() {
            document.getElementById('firstCategoryInput').scrollIntoView({behavior: 'smooth'});
        },

        iesire() {

            axios
            .post(this.$store.state.apiUrl, {action: 'logout'}, {withCredentials: true})
            .then(response => { // console.log(response);
            
                // succes
                if(response.data.state === 'success') {

                    // clear user data
                    this.$store.commit('SET_USER', '');
                    // msg success
                    this.$store.commit('SET_MODAL_CONFIRM', {type: 'success', msg: 'Ai ieșit cu succes din sistem.'});
                    // window.location.reload();
                    // window.location(this.$store.getters.getAppRoute);
                    window.location.assign(this.$store.getters.getAppRoute);

                // error
                } else {
                    this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE 1591259496: Dezautentificarea nu a putut fi efectuată. Te rog reîncarcă pagina și încearcă din nou.'});
                }

            })
            // error
            .catch(error => {
                this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE 1591259497: Dezautentificarea nu a putut fi efectuată. Te rog reîncarcă pagina și încearcă din nou.'});
            })
        },

        // check new orders every 2 minutes
        checkNewOrdersNr() {
            setInterval(() => {

                this.$store.dispatch('fetchNewOrdersNr');
                
            // }, 60000); // 1 minut
            }, 120000); // 2 minute
        }

    },
    mounted() {
        
        // check new orders on load
        this.$store.dispatch('fetchNewOrdersNr');
        // check periodically
        this.checkNewOrdersNr();

    }
}
</script>

<style scoped>
    .underlined {
        text-decoration: underline;
    }
    .router-link-exact-active {
        text-decoration: underline;
    }
    #admin-btns-container {
        justify-content: center;
    }
    #admin-btns-container * {
        color: #FFF;
        padding: 3px;
    }
    #admin-btns-container a:hover {
        text-decoration: underline;
        color: #FFF;
    }
    .modal {
        justify-content: start;
        padding: 10px;
        padding-top: 5%;
    }
    .modal-card {
        border-radius: 6px;
        padding-bottom: 60px;
    }
    .modal-card-body {
        border-radius: 6px;
    }
    .tabs {
        margin-bottom: 0 !important;
    }
    .tabs a {
        color: #999 !important;
    }
    .tabs .is-active a {
        color: black !important;
        border-bottom: 1px solid #666;
    }
    .tab-content {
        padding: 25px 15px 15px;
        border-right: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
    }
</style>