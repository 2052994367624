<template>
    <div class="has-text-centered">

        <!-- titlu abonamente disponibile -->
        <!-- <div class="is-size-5 margin-5 has-text-primary"><u>CUMPĂRĂ UN ABONAMENT:</u></div> -->
        
        <!-- tabel abonamente disponibile -->
        <table>
            <!-- loop abonamente -->
            <tr v-for="tipAbonament in $store.state.user.tipuri_abonamente" :key="tipAbonament.abonament_tip_id">
                <td class="is-size-5 has-text-primary"><b>{{tipAbonament.abonament_nume}}:</b>&nbsp;</td>
                <td>{{parseInt(tipAbonament.abonament_pret).toFixed(2)}} lei</td>
                <td>


                    <!-- de acord: send to netopia -->
                    <a v-if="acord" :href="`${$store.state.appUrl}/abonament/cardRedirect.php?abonamentTipId=${tipAbonament.abonament_tip_id}`" class="button is-primary is-outlined is-small mt-5" title="Către Netopia Payments: https://secure.mobilpay.ro">
                        <span>PLĂTEȘTE</span>
                        <span class="icon is-small">
                            <i class="far fa-hand-point-right"></i>
                        </span>
                    </a>

                    <!-- acord neexprimat: open avertizare -->
                    <a v-else @click="avertismentAcord = true" class="button is-primary is-outlined is-small mt-5" title="Către Netopia Payments: https://secure.mobilpay.ro">
                        <span>PLĂTEȘTE</span>
                        <span class="icon is-small">
                            <i class="far fa-hand-point-right"></i>
                        </span>
                    </a>


                </td>
            </tr>
        </table>

        <!-- avertisment acord -->
        <div v-if="avertismentAcord" class="has-text-white has-background-danger" style="max-width: 700px; border-radius: 3px; margin: 10px auto;">Trebuie să îți expimi acordul bifând câmpul de mai jos ca să poți cumpăra un abonament.</div>

        <!-- acord -->
        <div id="acord-utilizare-container">
            <input @click="checkAvertismentAcord()" v-model="acord" type="checkbox" id="acord-utilizare-platforma"> Cumpărând un abonament declari că ești de acord cu <a href="https://livrari.eu/termeni-si-conditii-de-utilizare.php" target="_blank">"Termenii și condițiile de utilizare"</a> și cu "<a href="https://livrari.eu/confidentialitatea-datelor.php" target="_blank">Politica de confidențialitate a datelor</a>"
        </div>

        <!-- detalii plata -->
        <div class="is-size-6 is-size-7-mobile has-font-weight-light has-text-weight-light detalii-plata">
            Click pe butonul "PLĂTEȘTE" pentru a fi redirecționat pe site-ul securizat <span class="has-text-weight-normal">Netopia PAYMENTS</span> (https://secure.mobilpay.ro/) unde poți efectua plata cu cardul <span class="has-text-weight-normal">Visa/MasterCard</span>. Imediat ce procesatorul de plăți ne va confirma plata, abonamentul tău va fi activat.
            <br><br>
            Plata va fi efectuată către Bucur I. Ionut Florin PFA, CUI: 27398235, nr. Reg. Com.: F29/1721/2010, str. T. Vladimirescu nr. 9, Ploiești/PH. Dacă vrei să faci plata prin transfer bancar o poți face în contul: <span class="has-text-weight-normal">RO28 BTRL 0300 1202 W606 59XX</span> deschis la <span class="has-text-weight-normal">Banca Transilvania</span>, sucursala Ploiești specificând la "detalii plată" tipul de abonament dorit. Trimite confirmarea plății unui abonament la adresa <span class="has-text-weight-normal">abonament@livrari.eu</span> sau prin <a :href="`https://api.whatsapp.com/send?phone=+40749412641&text=Am%20platit%20abonamentul.%20Client%20id:%20${$store.state.user.user_id}`" target="_blank">WhatsApp</a> la telefon <a href="tel:0749412641">0749 41 26 41</a>
            <br><br>
            <span class="has-text-weight-normal">Conform legislației în vigoare ai la dispoziție 14 zile în care poți cere returnarea integrală a banilor dacă decizi să nu folosești serviciile platformei.</span>
            <br><br>
            <span class="is-size-7">Dacă întâmpini probleme ne poți scrie la adresa <span class="has-text-weight-normal">abonament@livrari.eu</span> sau la telefon <a href="tel:0749412641">0749 41 26 41</a> / <a :href="`https://api.whatsapp.com/send?phone=+40749412641&text=Nu%20am%20reusit%20sa%20platesc%20abonamentul.%20Client%20id:%20${$store.state.user.user_id}`"  target="_blank">WhatsApp</a></span>
        </div>

        <img src="../../../src/assets/netopia_banner_blue.jpg" alt="Netopia Payments">
    </div>
</template>

<script>
// import axios from 'axios';
export default {
    name: 'CumparaAbonament',
    data() {
        return {
            acord: false,
            avertismentAcord: false
        }
    },
    methods: {
        checkAvertismentAcord() {

            setTimeout(() => {
                if(this.acord === true) {
                    this.avertismentAcord = false;
                }    
            }, 100);

        }
    }

}
</script>

<style scoped>
    table {
        margin: 0 auto;
    }
    td {
        vertical-align: middle !important;
        text-align: right !important;
        padding: 0 3px !important;
    }
    button {
        margin: 8px !important;
    }
    #acord-utilizare-container {
        max-width: 980px;
        margin: 10px auto;
        border: 1px solid #DDD;
        border-radius: 6px;
        padding: 10px;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
    .detalii-plata {
        display: block;
        background-color: #f3f3f3;
        border-radius: 5px;
        padding: 15px;
        max-width: 920px;
        margin: 20px auto;
    }
</style>