<template>
    <div>
        <!-- email -->
        <div class="field">
            <p class="control has-icons-left">
                <input v-model="email" class="input" type="email" placeholder="Email" @keyup.enter="autentificare()">
                <span class="icon is-small is-left">
                    <i class="far fa-envelope"></i>
                </span>
            </p>
        </div>
        
        <!-- password -->
        <div class="field">
            <p class="control has-icons-left">
                <input v-model="parola" id="passwordInput" class="input" type="password" placeholder="Parola" @keyup.enter="autentificare()">
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
            </p>
            <p @click="showHidePassword()" class="has-text-centered has-text-grey is-pointer is-size-7 has-text-weight-light margin-5" title="Click pentru a vedea/ascunde parola">(arată/ascunde parola)</p>
        </div>
        <br>
        
        <!-- login btn -->
        <div class="has-text-centered">
            <a
                class="button is-info is-outlined"
                :class="{ 'is-loading' : loadingBtn }"
                @click="autentificare()"
            >Autentificare</a>
        </div>
        
        <!-- show orders btn => if orders === company -->
        <div v-if="pastOrders" class="is-size-5 has-text-centered has-text-weight-light" style="margin-top: 15px;">
            <a @click="$router.replace('/comenzi-trimise')" class="button has-text-weight-light is-danger is-outlined">
                ISTORIC COMENZI TRIMISE
            </a>
            <!-- <a @click="$store.commit('SHOW_CLIENT_ORDERS_MODAL', true), $router.go(-1)" class="button has-text-weight-light is-danger is-outlined">
                ISTORIC COMENZI TRIMISE
            </a> -->
        </div>
        <br>

        <!-- <ModalResponse/> -->
    </div>
</template>

<script>
import axios from 'axios';
// import ModalResponse from '@/components/ModalResponse.vue'

export default {
    name: 'Autentificare',
    components: {
        // ModalResponse
    },
    data() {
        return {
            email: '',
            parola: '',
            loadingBtn: false,
            pastOrders: false
        }
    },
    methods: {
        showHidePassword() {
            let input = document.getElementById('passwordInput');
            input.type === 'password' ? input.type = 'text' : input.type = 'password';
        },

        autentificare() {

			if((this.email.trim() != '') && (this.parola.trim() != '')) {
				// check email
				if(!this.email.match(/\S+@\S+\.\S+/)) {
					
					this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: adresa de email introdusă nu este corectă!'});
					return;	
				}
                // loading btn
				this.loadingBtn = true;
                // send request
				axios
					.post(this.$store.state.apiUrl + '1/', {
						email: this.email,
						parola: this.parola,
						action: 'autentificare',
					}, {withCredentials: true})
					.then(response => { // console.log(response.data);
						// success	
						if(response.data.state === 'success') {

							// localStorage.setItem('localStorage', JSON.stringify(response.data.localStorage));
                            // window.location.href = "/administrare"; // redirect
                            // this.$router.push('/administrare');
                            
                            // close modal
                            // this.$store.commit('LOGIN_MODAL_TOGGLER');
                            // set message
                            this.$router.replace(this.$store.getters.getAppRoute);
                            this.$store.commit('SET_MODAL_CONFIRM', {type: 'success', msg: 'Autentificarea a fost efectuată cu succes!'});
                            window.location.reload();
                            // this.$store.commit('SET_AUTHENTICATED', true);
						// eroare
						} else {
							
							this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'Adresa de email sau parola incorecte.'});
						}
                    })
                    .catch(() => {
                            // this.$store.commit('LOGIN_MODAL_TOGGLER');
                            this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE 1591262512: conexiunea cu server-ul a fost pierdută. Te rog reîncarcă pagina și încearcă din nou.'})
                        }
                    )
                    .finally(() => this.loadingBtn = false)
			}
		}
    },
    created() {

        // check if past orders
        if(localStorage.getItem('orders')) {
            try {
                
                let orders = JSON.parse(localStorage.getItem('orders'));
                // at least one order from this company
                let order = orders.find(order => order.companyId == this.$store.state.company.user_id);
                // show btn
                if(order) { this.pastOrders = true; }
                    
            }
            catch(e) {
                localStorage.removeItem('orders');
            }
            
        }
    }
}
</script>

<style scoped>

</style>