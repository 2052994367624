<template>
    <div id="header-container">

        <div v-if="offline" class="has-text-centered margin-5 has-text-danger has-text-weight-bold" style="border: 1px solid #f14668;">Atenție! Nu ești conectat la internet.</div>
        
        <!-- header admin bar -->
        <div v-if="$store.getters.isOwner">
            <HeaderAdminBar/>
        </div>

        <!-- header logo bar -->
        <div>
            <HeaderLogoBar/>
        </div>

        <router-view name="menu"></router-view> <!-- HeaderMenuBar/HeaderMenuComenzi -->

        <!-- header menu bar || comenzi -->
        <!-- <div v-if="$store.state.showProducts">
            <HeaderMenuBar/>
        </div> -->
        <!-- meniu comenzi if auth -->
        <!-- <div v-else-if="$store.getters.isOwner">
            <HeaderMenuComenzi/>
        </div> -->
        <!-- <div v-else>
            <HeaderMenuBar/>
        </div> -->

        <!-- <ModalClientOrders/> -->

        <router-view></router-view><!-- modals: ModalCart, ModalInfo, ModalUser-->

    </div>
</template>

<script>

import HeaderAdminBar from '@/components/header/HeaderAdminBar.vue';
import HeaderLogoBar from '@/components/header/HeaderLogoBar.vue';
import HeaderMenuBar from '@/components/header/HeaderMenuBar.vue';
import HeaderMenuComenzi from '@/components/header/HeaderMenuComenzi.vue';
// import ModalClientOrders from '@/components/ModalClientOrders.vue';

export default {
    name: 'Header',
    components: {
        HeaderAdminBar,
        HeaderLogoBar,
        HeaderMenuBar,
        HeaderMenuComenzi
        // ModalClientOrders
    },
    data() {
		return {
			offline: false
		}
	},
    methods: {
        // saveOnReload() {
        //     sessionStorage.setItem('c', encodeURIComponent(JSON.stringify(this.$store.state.cart)));
        // }
        
    },
    created() {
        // window.addEventListener('beforeunload', this.saveOnReload);
    },
    mounted() {
        window.onoffline = (event) => {
			this.offline = true;
			console.log('connection lost');
		}

		window.ononline = (event) => {
			this.offline = false;
			console.log('connected');
		}
    }
}
</script>

<style scoped>
    #header-container {
        width: 100%;
    }
</style>