var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header-logo-bar"}},[_c('div',{staticClass:"column"},[_c('a',{staticClass:"info-icon is-hidden-tablet",on:{"click":function($event){return _vm.$router.push('/info')}}},[_vm._m(0)]),(
        _vm.$store.state.company.program_livrari ||
          _vm.$store.state.company.localitati_livrare
      )?_c('div',{staticClass:"is-hidden-mobile is-pointer has-text-grey-light has-text-weight-light has-text-centered is-size-6 is-size-5-fullhd",staticStyle:{"line-height":"22px","height":"100%"},attrs:{"title":"Info comenzi & livrare"},on:{"click":function($event){return _vm.$router.push('/info')}}},[(_vm.$store.state.company.localitati_livrare)?_c('h1',[_vm._v(" Livrare în "+_vm._s(_vm.$store.state.company.localitati_livrare)+" ")]):_vm._e(),(_vm.$store.state.company.program_livrari)?_c('div',{staticClass:"is-size-6 has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.$store.state.company.program_livrari)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"column is-narrow is-size-4 is-size-3-tablet is-size-2-fullhd has-text-primary has-text-weight-light has-text-centered is-pointer",staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.$router.push('/info')}}},[_vm._v(" "+_vm._s(_vm.$store.state.company.company)+" "),(_vm.$store.state.company.nu_primim_comenzi === '1')?_c('div',{staticClass:"is-size-6 has-text-danger has-text-weight-bold"},[_vm._v(" MOMENTAN, NU PRIMIM COMENZI! ")]):_vm._e()]),_c('div',{staticClass:"column"},[(
        _vm.$store.state.company.phone ||
          _vm.$store.state.company.numerar === '1' ||
          _vm.$store.state.company.card === '1'
      )?_c('div',{staticClass:"is-hidden-mobile has-text-grey-light has-text-centered has-text-weight-light is-size-6 is-size-5-fullhd",staticStyle:{"line-height":"22px","height":"100%"}},[_c('div',[(_vm.$store.state.company.phone)?_c('div',[_c('a',{staticClass:"has-text-grey-light",attrs:{"href":'tel:' + _vm.$store.state.company.phone}},[_vm._v("Telefon livrări: "),_c('span',{staticClass:"has-text-weight-normal"},[_vm._v(_vm._s(_vm._f("phoneNumber")(_vm.$store.state.company.phone)))])])]):_vm._e(),_c('div',{staticClass:"is-size-6 has-text-weight-normal"},[_vm._v(" Plata "),(_vm.$store.state.company.numerar === '1')?_c('span',[_vm._v("numerar")]):_vm._e(),(
              _vm.$store.state.company.numerar === '1' &&
                _vm.$store.state.company.card === '1'
            )?_c('span',[_vm._v("/")]):_vm._e(),(_vm.$store.state.company.card === '1')?_c('span',[_vm._v("card")]):_vm._e(),(
              _vm.$store.state.company.numerar === '1' ||
                _vm.$store.state.company.card === '1'
            )?_c('span',[_vm._v(" (la livrare)")]):_vm._e(),(
              (_vm.$store.state.company.numerar === '1' ||
                _vm.$store.state.company.card === '1') &&
                _vm.$store.state.company.transfer === '1'
            )?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.$store.state.company.transfer === '1')?_c('span',[_vm._v(" transfer bancar")]):_vm._e()])])]):_vm._e(),(!_vm.$store.getters.isOwner)?_c('a',{staticClass:"user-icon is-hidden-touch",on:{"click":function($event){return _vm.$router.push('/user')}}},[_vm._m(1)]):_vm._e(),_c('a',{staticClass:"user-icon is-hidden-desktop",on:{"click":function($event){return _vm.$router.push('/user')}}},[_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-shipping-fast is-size-5 is-size-5-touch has-text-primary"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"far fa-user is-size-5 is-size-5-touch has-text-primary"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"far fa-user is-size-5 is-size-5-touch has-text-primary"})])}]

export { render, staticRenderFns }