import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'
import store from './store'
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

Vue.use(VueAnalytics, {
  id: 'UA-172045669-1',
  router
});

Vue.config.productionTip = false

require("./assets/main.scss");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
