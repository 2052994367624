<template>
    <div id="footer-content" class="has-text-white has-text-weight-light justify-content-space-between">

        <!-- cookie banner -->
        <div v-if="!$store.state.acceptCookies" id="cookie-notice" class="is-size-7-mobile">
            <span>La fel ca celelalte site-uri, și noi folosim cookies.</span>
            <button  @click="$router.push('utilizare-cookies')" class="button is-small is-primary is-inverted is-outlined">DETALII</button>
            <button @click="acceptCookies()" class="button is-small is-primary is-inverted is-outlined">INCHIDE</button> 
        </div>

        <!-- termeni si conditii pentru abonat -->
        <div v-if="abonatTermeni">
            <div>
                &bull; <a href="https://livrari.eu/utilizare-cookies.php" target="_blank">Utilizare cookies</a>
            </div>

            <div>
                &bull; <a href="https://livrari.eu/confidentialitatea-datelor.php" target="_blank">Confidențialitatea datelor</a>
            </div>

            <div>
                &bull; <a href="https://livrari.eu/termeni-si-conditii-de-utilizare.php" target="_blank">Termeni și condiții de utilizare</a>
            </div>
        </div>

        <!-- MOBILE footer, magazin only -->
        <div v-if="$store.state.showProducts" class="is-hidden-desktop is-flex justify-content-space-between">
            
            <!-- icon info abonat -->
            <div v-if="$store.getters.isOwner" @click="abonatTermeni = !abonatTermeni" class="is-flex" style="padding: 10px 20px;">
                <span class="icon">
                    <i class="fab fa-readme is-size-5"></i>
                </span>
            </div>


            <!-- icon info client -->
            <div v-else @click="$router.push('/termeni-si-conditii')" class="is-flex" style="padding: 10px 20px;">
                <span class="icon">
                    <i class="fab fa-readme is-size-5"></i>
                </span>
            </div>


            <!-- open ad -->
            <div @click="footerAd = true" v-if="footerAd === false && !$store.getters.isOwner" id="openFooterAd">
                <i class="fa fa-angle-up is-size-6"></i>
            </div>
            
            <!-- icon open cart -->
            <div @click="$router.push('/cart')" class="is-flex" style="padding: 10px 15px;">
                <span class="icon">
                    <i class="fas fa-shopping-cart is-size-5"></i>
                </span>
                <span v-if="$store.getters.totalToPay" style="height: 24px;">
                    {{computedReload}} <!-- show spinner -->
                    <div v-if="updateCart">
                        <span class="icon" style="margin: 0 21px;">
                            <i class="fas fa-spinner fa-pulse is-size-7"></i>
                        </span>
                    </div>
                    <div v-else>
                        : {{$store.getters.totalToPay | dotToComma}} lei
                    </div>
                </span>
            </div>
        </div>
        
        <!-- DESKTOP -->
        <div class="is-hidden-touch is-size-6 has-text-weight-light">

            <!-- srl data -->
            <div class="is-size-7 has-text-weight-light footer-desktop">
                
                <!-- open ad -->
                <!-- <span @click="footerAd = true" v-if="footerAd === false"><i class="fas fa-info-circle is-size-6"></i></span> -->
                <span @click="footerAd = true" v-if="footerAd === false && !$store.getters.isOwner"><i class="fas fa-info-circle is-size-6"></i></span>

                &nbsp;&nbsp; &bull;&nbsp;<span v-if="$store.state.company.company_name">{{$store.state.company.company_name}}</span>
            
                <!-- abonati -->
                <template v-if="$store.getters.isOwner">
                    <span>
                        &bull; <a href="https://livrari.eu/utilizare-cookies.php" target="_blank">Utilizare cookies</a>
                    </span>

                    <span>
                        &bull; <a href="https://livrari.eu/confidentialitatea-datelor.php" target="_blank">Confidențialitatea datelor</a>
                    </span>

                    <span>
                        &bull; <a href="https://livrari.eu/termeni-si-conditii-de-utilizare.php" target="_blank">Termeni și condiții de utilizare</a>
                    </span>
                </template>


                <!-- clienti -->
                <template v-else>
                    <span>
                        &bull; <a @click="$router.push('/utilizare-cookies')">Utilizare cookies</a>
                    </span>

                    <span>
                        &bull; <a @click="$router.push('/confidentialitatea-datelor')">Confidențialitatea datelor</a>
                    </span>

                    <span>
                        &bull; <a @click="$router.push('/termeni-si-conditii')">Termeni și condiții de utilizare</a>
                    </span>
                </template>

                &bull; <a href="https://anpc.ro" target="_blank">PROTECȚIA CONSUMATORILOR – ANPC</a>

            </div>

        </div>

        <!-- footer ad -->
        <div v-if="footerAd" id="footerAdContainer">
            <a href="https://livrari.eu/#inregistrare-section" target="_blank">VREI ȘI TU UN MAGAZIN ONLINE CA ACESTA?</a>
            <div class="footerAdBtns"><a href="https://livrari.eu/#inregistrare-section" target="_blank">DA</a></div>
            <div @click="footerAd = false" class="footerAdBtns">NU</div>
        </div>
    </div>
    
</template>
<script>
export default {
    name: 'Footer',
    data() {
        return {
            updateCart: false,
            footerAd: false,
            abonatTermeni: false
        }
    },
    methods: {
        acceptCookies() {
            this.$store.state.acceptCookies = true;
            localStorage.setItem('cookie-accepted', true);
        }
    },
    computed: {
        computedReload() { // animate cart icon
            this.$store.getters.totalToPay;
            this.updateCart = true; 
            setTimeout(() => { this.updateCart = false; }, 300);
        }
    },
    filters: {
        dotToComma: string => string.replace('.',',')
    },
    mounted() {
		if(localStorage.getItem('cookie-accepted') !== null) {
			this.$store.state.acceptCookies = true;
		}
    }
}
</script>
<style scoped>
    #cookie-notice {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #FFF;
    }
    #cookie-notice * {
        margin: 5px;
    }
    #admin-btn {
        margin-top: 5px;
    }
    .border-bottom {
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        padding-bottom: 5px;
    }
    a {
        color: #FFF;
    }
    a:hover {
        color: #FFF;
        text-decoration: underline;
    }
    .footer-desktop {
        padding: 6px;
    }
    #footerAdContainer {
        font-size: 12px !important;
        padding: 10px 0;
        max-width: 350px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .footerAdBtns {
        border: 1px solid #FFF;
        padding: 3px 7px;
        border-radius: 3px;
    }
    #openFooterAd {
        position: absolute;
        opacity: .8;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
    }
</style>