import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

import HeaderMenuBar from '@/components/header/HeaderMenuBar.vue';
import ProductsGrid from '@/components/ProductsGrid.vue';
import Footer from '@/components/Footer.vue';

const ModalCart = () => import('@/components/ModalCart.vue');
const ModalSendOrder = () => import('@/components/ModalSendOrder.vue');
const ModalClientOrders = () => import('@/components/ModalClientOrders.vue');
const ModalInfo = () => import('@/components/ModalInfo.vue');
const ModalUser = () => import('@/components/ModalUser.vue');
const HeaderMenuComenzi = () => import('@/components/header/HeaderMenuComenzi.vue');

const ModalGestiuneCategoriiProduse = () => import('@/components/admin/ModalGestiuneCategoriiProduse.vue');

const ModalEditProduct = () => import('@/components/admin/ModalEditProduct.vue');
const Comenzi = () => import('@/components/admin/Comenzi.vue');
const ModalOrder = () => import('@/components/admin/ModalOrder.vue');
const ModalTermeniSiConditii = () => import('@/components/ModalTermeniSiConditii.vue');
const ModalConfidentialitateaDatelor = () => import('@/components/ModalConfidentialitateaDatelor.vue');
const ModalUtilizareCookies = () => import('@/components/ModalUtilizareCookies.vue');

// import axios from 'axios';

Vue.use(VueRouter)

const routes = [
	{
		path: '/cart',
		name: 'cart',
		components: {
			default: ModalCart,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/trimite-comanda',
		name: 'trimiteComanda',
		components: {
			default: ModalSendOrder,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/comenzi-trimise',
		name: 'comenziTrimise',
		components: {
			default: ModalClientOrders,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/termeni-si-conditii',
		name: 'termeniSiConditii',
		components: {
			default: ModalTermeniSiConditii,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/confidentialitatea-datelor',
		name: 'confidentialitateaDatelor',
		components: {
			default: ModalConfidentialitateaDatelor,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/utilizare-cookies',
		name: 'utilizareCookies',
		components: {
			default: ModalUtilizareCookies,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/info',
		name: 'info',
		// component: ModalInfo,
		components: {
			default: ModalInfo,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/user',
		name: 'user',
		// component: ModalUser,
		components: {
			default: ModalUser,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/comenzi',
		name: 'comenzi',
		components: {
			menu: HeaderMenuComenzi,
			mainContent: Comenzi
		},
		beforeEnter: (to, from, next) => {
			if(store.state.authenticated === false) {				
				next('/');
			} else {
				next();
			}
		}
	},
	{
		path: '/detalii-comanda/:comandaId',
		props: true,
		name: 'detaliiComanda',
		components: {
			default: ModalOrder,
			menu: HeaderMenuComenzi,
			mainContent: Comenzi
		},
		// props: true,
		beforeEnter: (to, from, next) => {
			if(store.state.authenticated === false) {				
				next('/');
			} else {
				next();
			}
		}
	},
	{
		path: '/gestiune',
		name: 'ModalGestiuneCategoriiProduse',
		components: {
			default: ModalGestiuneCategoriiProduse,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/modifica-produs',
		name: 'modificaProdus',
		components: {
			default: ModalEditProduct,
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	},
	{
		path: '/:categoryUrl',
		// path: '/categorie/:categoryUrl',
		name: 'categorie',
		components: {
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		},
		props: true,
		// beforeRouteUpdate(to, from, next) {
		// 	console.log('reusing this component');
			
		// 	console.log('entering categorie: ' + to.params.categoryUrl);
		// 	next();			
		// },
		// beforeEnter: (to, from, next) => {
		// 	console.log('entering categorie: ' + to.params.categoryUrl);
		// 	// console.log(to);
		// 	// console.log(from);
		// 	// console.log(next);	
		// 	next();
		// }
	},
	{
		path: '*',
		name: 'magazin',
		components: {
			menu: HeaderMenuBar,
			mainContent: ProductsGrid,
			footer: Footer
		}
	}
	// {
	// 	path: '/',
	// 	name: 'home',
	// 	component: Home
	// },
	//   {
	// 	path: '/meniu/categorie/:id',
	// 	name: 'categorie_selectata',
	// 	component: () => import(/* webpackChunkName: "categorie_selectata" */ '../views/Meniu.vue')
	//   },
  	// {
	// 	path: '*',
	// 	// name: 'home',
	// 	component: Home
  	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})




// https://stackoverflow.com/questions/43424302/how-to-protect-client-side-routes-in-vue-js/43425049
// verifica autentificare
// router.beforeEach((to, from, next) => {
	
// 	let web = ["administrare", "comenzi"];
	
// 	if(web.includes(to.name)){
        
// 		axios
// 			.get(store.state.apiUrl + 'get/administrare/user', {withCredentials: true})
// 			.then(response => {
// 				if(response.data.status === 'autentificat') {
					
// 					// store.user = response.data;
// 					next();

// 				} else {
					
// 					router.push({
// 						name: 'home',
// 						// params:{
// 						// 	serverError:true,
// 						// 	serverMsg: 'Please login to continue.'
// 						// }
// 					});
// 				}
// 			});
// 			// .catch(response=> {
// 			//     console.log(response);
// 			// });
		
//     } else {
		
// 		next();
// 	}
// });




export default router
