<template>
    <div id="container">
        
        <!-- tabs -->
        <div class="tabs">
            <ul>
                <li :class="{ 'is-active' : formAutentificare }">
                    <a @click="formAutentificare = true">Autentificare</a>
                </li>
                <li :class="{ 'is-active' : !formAutentificare }">
                    <a @click="formAutentificare = false">Creare cont</a>
                </li>
            </ul>
        </div>

        <!-- autentificare -->
        <div v-if="formAutentificare">
            <Autentificare/>
        </div>

        <!-- cont nou -->
        <div v-else>
            <!-- nume -->
            <div class="is-flex">
                <div class="field  input-container">
                    <p class="control has-icons-left">
                        <input v-model="nume" class="input" type="text" placeholder="Nume vânzător" maxlength="50">
                        <span class="icon is-small is-left">
                            <i class="far fa-user"></i>
                        </span>
                    </p>
                </div>
                <div  @click="showMsg('nume')" class="input-icon-container">
                    <span class="icon">
                        <i class="far fa-lg fa-question-circle"></i>
                    </span>
                </div>
            </div>

            <!-- url -->
            <div v-if="nume" style="padding-bottom: 15px;" class="is-size-6 is-size-7-mobile">
                <!-- mobile -->
                <div class="is-hidden-tablet">
                    <span class="has-text-grey-light has-text-weight-light">adresa ta va fi: </span>
                    <span>https://{{createUrl}}.livrari.eu</span>
                </div>
                <!-- desktop -->
                <div class="is-hidden-mobile">
                    <span class="has-text-grey-light has-text-weight-light" style="margin: 0 10px;">adresa ta va fi:</span>
                    <span>https://{{createUrl}}.livrari.eu</span>
                </div>
            </div>


            <!-- email -->
            <div class="is-flex">
                <div class="field input-container">
                    <p class="control has-icons-left">
                        <input v-model="email" class="input" type="email" placeholder="Email">
                        <span class="icon is-small is-left">
                            <i class="far fa-envelope"></i>
                        </span>
                    </p>
                </div>
                <div @click="showMsg('email')" class="input-icon-container">
                    <span class="icon">
                        <i class="far fa-lg fa-question-circle"></i>
                    </span>
                </div>
            </div>
            <!-- password -->
            <div class="is-flex">	
                <div class="field input-container">
                    <p class="control has-icons-left">
                        <input v-model="parola" class="input" type="password" placeholder="Parola (cel puțin 8 caractere)">
                        <span class="icon is-small is-left">
                            <i class="fa fa-lock"></i>
                        </span>
                    </p>
                </div>
                <div @click="showMsg('parola')" class="input-icon-container">
                    <span class="icon">
                        <i class="far fa-lg fa-question-circle"></i>
                    </span>
                </div>
            </div>
            <!-- btn -->
            <div class="has-text-centered">
                <a
                    class="button is-info is-outlined"
                    :class="{ 'is-loading' : loadingBtn }"
                    @click="contNou"
                >Creează cont nou</a>
            </div>
        </div>
        <ModalConfirm/>
    </div>
</template>

<script>
import axios from 'axios';
import Autentificare from '@/components/Autentificare.vue';
import ModalConfirm from '@/components/ModalConfirm.vue'


export default {
    name: 'NewAccount',
    components: {
        Autentificare,
        ModalConfirm
    },
    data() {
		return {
			formAutentificare: false,
			nume: '',
			email: '',
			parola: '',
			loadingBtn: false
		}
    },
    methods: {
		// ...mapMutations(['SET_MODAL_CONFIRM']),
		showMsg(payload) {

			switch (payload) {
				case 'nume':
                    // this.$store.commit('SET_MODAL_CONFIRM', {type: 'info', msg: 'EROARE: adresa de email introdusă nu este corectă!'});
					this.$store.commit('SET_MODAL_CONFIRM', {type: 'info', msg: 'Inserează un nume pentru afacerea ta. Ex.: Bistro Bulevard, Cofetăria Christine (îl poți schimba mai tărziu). Poți introduce numai litere și cifre.'});
					break;

				case 'email':
					this.$store.commit('SET_MODAL_CONFIRM', {type: 'info', msg: 'O vei folosi la autentificare și va fi principalul nostru mijloc de comunicare. Tot aici vei primi și comenzile.'});
					break;

				case 'parola':
					this.$store.commit('SET_MODAL_CONFIRM', {type: 'info', msg: 'Alege o parolă greu de ghicit formată din litere, cifre și caractere speciale. Trebuie să conțină cel puțin 8 caractere.'});
					break;
			}
		},

		contNou() {

			// check nume
			if(this.nume.trim() == '') {
				this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: trebuie să alegi un nume de vânzător (ex: "Nume Firmă").'});
				return;
			}

			// check email
			if(!this.email.match(/\S+@\S+\.\S+/)) {
				this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: adresa de email introdusă nu este corectă!'});
				return;	
			}

			// check password > 8
			if(this.parola.length < 8) { // parola
				this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: parola trebuie să conțină cel puțin 8 caractere!'});
				return;
			}

			this.loadingBtn = true;
			// let axiosHeaders = { headers: {"Content-Type":"application/json", csrf_tkn:"123456" }};

			axios
				.post(this.$store.state.apiUrl + '1/', {
					nume: this.nume,
					email: this.email,
					parola: this.parola,
					action: 'contNou',
				}, {withCredentials: true})
                .then(response => { // console.log(response);
                
					// success	
					if(response.data.state === 'success') {

                        // localStorage.setItem('localStorage', JSON.stringify(response.data.localStorage));
                        // redirect production
                        if(window.location.hostname === 'livrari') {
                            window.location.href = "https://" + response.data.userUrl + ".livrari.eu";
                        // redirect dev
                        } else {
                            window.location.href = "/" + response.data.userUrl; // redirect
                        }
						this.$store.commit('SET_MODAL_CONFIRM', {type: 'success', msg: 'Contul a fost creat cu succes!'});

					// email format error
					} else if(response.data === 'emailFormatError') {

						this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: adresa de email introdusă nu este corectă!'});
					
					// email/user registered
					} else if(response.data === 'emailRegistered') {

						this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: există deja un cont creat cu această adresă de email'});

					// url registered
					} else if(response.data === 'urlRegistered') {

						this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: există deja un cont creat cu aceast nume. Încearcă te rog altul.'});

                    // eroare necunoscuta
					} else {	
						this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'Eroare necunoscută: contul nu a putut fi creat. Te rog încearcă din nou.'});
					}

	
                })
                .catch(error => {
						this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'Eroare de conexiune cu server-ul: contul nu a putut fi creat. Te rog încearcă din nou.'});
                })
                .finally(() => {
					this.loadingBtn = false;
                }) 

			// }
			
		}

    },
    computed: {
        createUrl: function() {
            let filteredUrl = this.nume.replace(/[^a-z0-9- ]/gi, "");
            let lowerCaseUrl = filteredUrl.toLowerCase();
            return lowerCaseUrl.replace(/ /g, '-');
        }
    }
}
</script>

<style>
    #container {
        max-width: 500px;
        margin:0 auto;
        padding: 20px;
    }
    .input-icon-container {
		padding: 9px;
		cursor: pointer;
	}
	.input-container {
		flex-grow: 1
	  }
	/* #central-content {
		margin: 110px auto 60px auto;
	}
	@media only screen and (max-width: 1023px) {
		#central-content {
			margin: 70px auto 60px auto;
		}
	} */
</style>