<template>
	<div id="app">
		<!-- loading data: user, categories, products in first category -->
		<div v-if="$store.state.loadingFirstData" class="has-text-centered">
			<br /><br />
			<span class="icon is-large  has-text-primary">
				<i class="fas fa-2x fa-spinner fa-pulse"></i>
			</span>
		</div>

		<!-- no valid url/company -->
		<div v-else-if="!$store.state.company" class="has-text-centered has-text-grey has-text-weight-light">
			<div class="is-size-4">
				<br /><br /><br />
				EROARE 404: <br />
				pagina nu a fost găsită.
			</div>
			<br /><br />
			<div class="is-size-5">
				Verifică adresa introdusă: https://<span class="has-text-weight-normal">{{ $store.state.wrongUrl }}</span
				>.livrari.eu/
			</div>
		</div>

		<!-- shop -->
		<div v-else>
			<!-- row 1: HEADER -->
			<div class="navbar is-fixed-top has-shadow">
				<Header />
			</div>

			<!-- row 2: CENTRAL CONTENT -->
			<div id="central-content" :class="{ 'padding-top-admin': $store.getters.isOwner }">
				<!-- central row -->
				<div v-if="$store.state.company.abonament === 'ok'" class="is-flex">
					<!-- ProductsGrid/Comenzi -->
					<div class="column">
						<router-view name="mainContent"></router-view>
					</div>

					<!-- cart & settings msgs column (not showing in comenzi) -->
					<div v-if="$route.path !== '/comenzi' && $store.state.categories" class="column is-narrow is-hidden-touch" style="width: 320px;">
						<!-- <div v-if="$store.getters.totalToPay > 0" class="column is-narrow is-hidden-touch" style="width: 320px;"> -->
						<Cart />
					</div>
				</div>

				<!-- abonament expirat -->
				<div v-else>
					<div class="has-text-centered is-size-4 is-size-5-mobile has-text-danger has-text-weight-light">
						<!-- vizitator -->
						<br />Magazinul este închis.

						<!-- user -->
						<div v-if="$store.getters.isOwner">
							<!-- no abonament acquisted -> after perioada de proba -->
							<!-- <div v-if="!$store.state.user.abonamente_data">Perioada de probă a expirat</div> -->

							Trebuie să cumperi un abonament.
						</div>
					</div>
					<br />
					<div v-if="$store.getters.isOwner">
						<CumparaAbonament />
					</div>
				</div>
			</div>

			<!-- footer row -->
			<nav class="navbar is-fixed-bottom has-text-centered has-background-primary" style="z-index: 10; justify-content: center;">
				<router-view name="footer"></router-view>
				<!-- Footer -->
			</nav>
		</div>
		<!-- end shop -->

		<ModalConfirm />
	</div>
</template>
<script>
import axios from "axios";
import store from "@/store/index";
import ModalConfirm from "@/components/ModalConfirm.vue";
import NewAccount from "@/components/NewAccount.vue";

import Header from "@/components/header/Header.vue";
import CumparaAbonament from "@/components/admin/CumparaAbonament";
import ProductsGrid from "@/components/ProductsGrid.vue";
import Cart from "@/components/Cart.vue";
import Comenzi from "@/components/admin/Comenzi.vue";
import Footer from "@/components/Footer.vue";

export default {
	components: {
		Header,
		CumparaAbonament,
		ProductsGrid,
		Cart,
		Comenzi,
		Footer,
		ModalConfirm,
		NewAccount,
	},
	data() {
		return {
			newAccount: false,
		};
	},
	filters: {
		dotToComma: (string) => string.replace(".", ","),
	},
	methods: {
		saveOnReload() {
			sessionStorage.setItem("c", encodeURIComponent(JSON.stringify(this.$store.state.cart)));
		},

		// check closed store
		checkTrimitereComenziActiv() {
			axios.post(this.$store.state.apiUrl + "1/", { action: "check-comenzi-on-off", restaurantId: this.$store.state.company.user_id }, { withCredentials: true }).then((response) => {
				if (response.data.state === "success") {
					this.$store.state.company.nu_primim_comenzi = response.data.payload.toString();
				}
			});
		},

		checkIfUserIsActive() {
			// check on return from other browser tab/change task manager on mobile
			document.addEventListener("visibilitychange", () => {
				if (document.visibilityState === "visible") {
					this.checkTrimitereComenziActiv();
				}
			});
			// check on interval
			setInterval(() => {
				if (document.hasFocus()) {
					// show/hide "MOMENTAN, NU PRIMIM COMENZI!"
					this.checkTrimitereComenziActiv();
				}
				// }, 1000);
			}, 300000); // = 5 minute
		},
	},
	created() {
		const hostname = window.location.hostname;
		// console.log(location.hostname); // 192.168.1.115 / livrari.eu / livrari.local
		const arrayHostname = hostname.split(".");

		// livrari.eu
		let userUrl = "";
		switch (arrayHostname[arrayHostname.length - 1]) {
			case "eu":
				this.$store.commit("SET_APP_URL", "https://livrari.eu");
				this.$store.commit("SET_API_URL", "https://livrari.eu/api/");
				userUrl = arrayHostname[arrayHostname.length - 3].replace(/[^a-z0-9-]/gi, "");
				break;

			case "local":
				this.$store.commit("SET_APP_URL", "http://livrari.local");
				this.$store.commit("SET_API_URL", "http://livrari.local/api/");
				userUrl = arrayHostname[arrayHostname.length - 3].replace(/[^a-z0-9-]/gi, "");
				break;

			// development
			default:
				this.$store.commit("SET_APP_URL", "http://192.168.1.115/livrari.eu");
				this.$store.commit("SET_API_URL", "http://192.168.1.115/livrari.eu/api/");
				/**
				 * set local user
				 */
				// userUrl = 'cofetaria-christine';
				userUrl = "aprozaresti";
				// userUrl = 'test-test';
				// userUrl = 'pizzeria-napoli';
				break;
		}
		// end livrari.eu

		this.$store.commit("SET_USER_URL", userUrl);
		this.$store.dispatch("firstLoad");

		window.addEventListener("beforeunload", this.saveOnReload);
	},
	mounted() {
		this.checkIfUserIsActive();

		// document.addEventListener("visibilitychange", this.handleVisibilityChange, false);

		if (sessionStorage.getItem("c")) {
			try {
				this.$store.state.cart = JSON.parse(decodeURIComponent(sessionStorage.getItem("c")));
			} catch (e) {
				sessionStorage.removeItem("c");
			}
		}
		sessionStorage.removeItem("c");
	},
};
</script>
<style lang="scss">
#app {
	max-width: 1920px;
	margin: 0 auto;
}
.padding-top-admin {
	padding-top: 15px;
}

#central-content {
	margin: 140px auto 100px auto;
}
#settingsColumn {
	transition: 0.1s ease;
}
.settings-column-opened {
	width: 350px;
}
.settings-column-closed {
	opacity: 0;
	display: none;
}
// @media only screen and (min-width: 1024px) {
//     .empty-cart-msgs {
//         position: -webkit-sticky;
//         position: sticky;
//         top: 140px;
//     }
// }
.navbar {
	min-height: 2rem !important;
}
.empty-cart-msgs {
	background-color: #f8f8f8;
	border-radius: 4px;
	margin-right: 5px;
	padding: 25px 10px;
	position: -webkit-sticky;
	position: sticky;
	top: 150px;
	border: 1px solid #eee;
}
@media only screen and (max-width: 1407px) {
	#central-content {
		margin: 130px auto 100px auto;
	}
}

@media only screen and (max-width: 1023px) {
	#central-content {
		margin: 120px auto 60px auto;
	}
	.padding-top-admin {
		padding-top: 20px;
	}
}

@media only screen and (max-width: 768px) {
	.padding-top-admin {
		padding-top: 10px;
	}
}

.is-pointer {
	cursor: pointer;
}
footer {
	border: none !important;
}
.button {
	border-radius: 4px !important;
}
.navbar.has-shadow {
	-webkit-box-shadow: 0 0.5em 1em -0.425em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02) !important;
	box-shadow: 0 0.5em 1em -0.425em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02) !important;
}
.no-border {
	border: 0 !important;
}
.has-border-bottom {
	border-bottom: 1px solid;
}
.has-danger-border {
	border: 1px solid #f14668 !important;
}
.has-border-ddd {
	border: 1px solid #ddd;
}
.border-radius-4 {
	border-radius: 4px;
}
.has-content-justified {
	justify-content: center;
}
.has-content-space-around {
	justify-content: space-around;
}
.has-content-space-evenly {
	justify-content: space-evenly;
}
.height-100 {
	height: 100%;
}
.width-100 {
	width: 100%;
}
.justify-content-space-between {
	justify-content: space-between;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-space-around {
	justify-content: space-around;
}
.justify-content-space-evenly {
	justify-content: space-evenly;
}

.align-items-center {
	align-items: center;
}

.align-content-flex-start {
	align-content: flex-start;
}
.align-content-center {
	align-content: center;
}
.margin-5 {
	margin: 5px;
}
.padding-5 {
	padding: 5px;
}
.mt-5 {
	margin-top: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-20 {
	margin-top: 20px !important;
}
.mr-5 {
	margin-right: 5px;
}
.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.ml-5 {
	margin-left: 5px;
}
.nowrap {
	white-space: nowrap;
}
.clear {
	clear: both;
}

/* hide input type number arrows Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* hide input type number arrows Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

// text selection
body {
	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	user-select: none;
}

.modal {
	animation: fadeIn 0.2s;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
	input[type="checkbox"],
	input[type="radio"] {
		--active: #009233;
		--active-inner: #fff;
		--focus: 2px rgba(39, 94, 254, 0.3);
		--border: #bbc1e1;
		--border-hover: #009233;
		--background: #fff;
		--disabled: #f6f8ff;
		--disabled-inner: #e1e6f9;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 21px;
		outline: none;
		display: flex;
		vertical-align: top;
		position: relative;
		margin: 0 5px 0 0;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
		&:after {
			content: "";
			display: block;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
		}
		&:checked {
			--b: var(--active);
			--bc: var(--active);
			--d-o: 0.3s;
			--d-t: 0.6s;
			--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
		}
		&:disabled {
			--b: var(--disabled);
			cursor: not-allowed;
			opacity: 0.9;
			&:checked {
				--b: var(--disabled-inner);
				--bc: var(--border);
			}
			& + label {
				cursor: not-allowed;
			}
		}
		&:hover {
			&:not(:checked) {
				&:not(:disabled) {
					--bc: var(--border-hover);
				}
			}
		}
		// &:focus {
		// box-shadow: 0 0 0 var(--focus);
		// }
		&:not(.switch) {
			width: 21px;
			&:after {
				opacity: var(--o, 0);
			}
			&:checked {
				--o: 1;
			}
		}
		& + label {
			font-size: 14px;
			line-height: 21px;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			margin-left: 4px;
		}
	}
	input[type="checkbox"] {
		&:not(.switch) {
			border-radius: 7px;
			&:after {
				width: 5px;
				height: 9px;
				border: 2px solid var(--active-inner);
				border-top: 0;
				border-left: 0;
				left: 7px;
				top: 4px;
				transform: rotate(var(--r, 20deg));
			}
			&:checked {
				--r: 43deg;
			}
		}
		&.switch {
			width: 38px;
			border-radius: 11px;
			&:after {
				left: 2px;
				top: 2px;
				border-radius: 50%;
				width: 15px;
				height: 15px;
				background: var(--ab, var(--border));
				transform: translateX(var(--x, 0));
			}
			&:checked {
				--ab: var(--active-inner);
				--x: 17px;
			}
			&:disabled {
				&:not(:checked) {
					&:after {
						opacity: 0.6;
					}
				}
			}
		}
	}
	input[type="radio"] {
		border-radius: 50%;
		&:after {
			width: 19px;
			height: 19px;
			border-radius: 50%;
			background: var(--active-inner);
			opacity: 0;
			transform: scale(var(--s, 0.7));
		}
		&:checked {
			--s: 0.5;
		}
	}
}
</style>
