<template>
	<div id="cart-container">
		<!-- empty cart: msgs / must have categories -->
		<div
			v-if="!$store.getters.totalToPay"
			class="empty-cart-msgs has-text-centered"
		>
			<!-- comanda minima -->
			<div v-if="$store.state.company.comanda_minima" class="is-size-4">
				Comanda minimă:<br />{{
					parseFloat($store.state.company.comanda_minima).toFixed(2)
						| dotToComma
				}}
				lei
			</div>

			<!-- livrare -->
			<div v-if="$store.state.company.pret_livrare" class="is-size-4">
				<br />
				Livrare
				{{
					parseFloat($store.state.company.pret_livrare).toFixed(2)
						| dotToComma
				}}
				lei
			</div>
			<div v-else>(livrare gratuită)</div>

			<!-- livrare minim gratuit (daca am pret livrare) -->
			<div
				v-if="
					$store.state.company.pret_livrare &&
						$store.state.company.minim_livrare_gratuita
				"
				class="is-size-5 is-italic"
			>
				<br />
				Livrare gratuită pentru comenzi mai mari de<br />
				<b
					>{{
						parseFloat(
							$store.state.company.minim_livrare_gratuita
						).toFixed(2) | dotToComma
					}}
					lei</b
				>
			</div>

			<!-- custom mesage -->
			<div
				v-if="$store.state.company.mesaj_personalizat"
				class="is-size-5"
			>
				<br />
				{{ $store.state.company.mesaj_personalizat }}
			</div>

			<!-- avizare telefon / localitate livrare obligatoriu -->
			<div v-if="$store.getters.isOwner">
				<div
					v-if="
						!$store.state.user.phone ||
							!$store.state.user.localitati_livrare
					"
					class="has-text-danger has-text-weight-light"
				>
					<br />
					Nu uita să adaugi numărul de telefon,
					localitatea/localitățile unde faci livrările și metoda de
					plată.
				</div>
			</div>

			<!-- modifica -->
			<div v-if="$store.getters.isOwner">
				<br />
				<a @click="$router.push('/user')" class="button is-small">
					<span>Configurează</span>
					<span class="icon">
						<i class="far fa-user is-size-7 has-text-primary"></i>
					</span>
				</a>
			</div>
		</div>

		<!-- cart products -->
		<!-- <div v-if="$store.getters.totalToPay > 0"> -->
		<div v-else>
			<!-- nu primim comenzi -->
			<button
				v-if="$store.state.company.nu_primim_comenzi === '1'"
				class="button is-fullwidth is-danger has-text-weight-bold"
				style="margin: 25px 0;"
			>
				MOMENTAN, NU PRIMIM COMENZI!
			</button>

			<div class="has-text-centered has-text-weight-light is-size-3">
				COMANDA TA
			</div>

			<div
				v-for="cartItem in $store.getters.sortedCart"
				:key="cartItem.id"
			>
				<div
					v-if="cartItem.qty > 0"
					class="has-text-weight-light grey-box"
				>
					<!-- delete from cart -->
					<span
						@click="deleteCartProduct(cartItem.id)"
						class="icon is-small has-text-danger is-pointer delete-item"
						title="Elimina produsul din comandă"
					>
						<i class="fas fa-times"></i>
					</span>

					<!-- btn edit -->
					<span
						@click="openProductModal(cartItem)"
						v-if="$store.state.company.shop_version > 0"
						class="edit-icon icon is-small has-text-grey-light is-pointer"
					>
						<i class="fas fa-pencil-alt"></i>
					</span>

					<!-- name -->
					<span
						@click="openProductModal(cartItem)"
						class="is-size-5 has-text-weight-normal is-pointer"
						:title="cartItem.product_name"
						>{{ cartItem.product_name }}</span
					>

					<!-- optiune pret -->
					<span v-if="cartItem.price_option" class="is-size-7">
						({{ cartItem.price_option }})</span
					>

					<!-- price name -->
					<span v-if="cartItem.productPriceName" class="">
						({{ cartItem.productPriceName }})</span
					>

					<!-- price/pc if topping || options -->
					<span
						v-if="
							cartItem.options.length != 0 ||
								cartItem.topping.length != 0
						"
						class="has-text-weight-normal"
					>
						: {{ cartItem.productPrice }} lei
					</span>

					<!-- options -->
					<div
						@click="openProductModal(cartItem)"
						v-if="cartItem.options.length != 0"
						style="padding-left: 20px;"
						class="is-pointer"
						title="Click pentru modificare"
					>
						<div
							v-for="option in cartItem.options"
							:key="option.id"
							class="is-flex option-container"
						>
							- {{ option.variant_name }}
							<span
								v-if="option.variant_price != 0"
								class="has-text-weight-normal"
								>:
								{{ option.variant_price | dotToComma }}
								lei</span
							>
						</div>
					</div>

					<!-- topping -->
					<div
						@click="openProductModal(cartItem)"
						v-if="cartItem.topping.length != 0"
						style="padding-left: 20px;"
						class="is-pointer"
						title="Click pentru modificare"
					>
						<div
							v-for="topping in cartItem.topping"
							:key="topping.id"
						>
							- {{ topping.topping_name }}
							<span
								v-if="topping.topping_price != 0"
								class="has-text-weight-normal"
								>:
								{{ topping.topping_price | dotToComma }}
								lei</span
							>
						</div>
					</div>

					<!-- qty/price/subtotal -->
					<div
						class="is-flex align-items-center justify-content-center"
						style="margin-top: 10px;"
					>
						<!-- qty -->
						<span class="qty-container">
							<!-- btn minus/trash qty -->
							<div
								v-show="cartItem.qty === 1"
								@click="deleteCartProduct(cartItem.id)"
								class="qty-minus-elimina"
							>
								<i
									class="fa fa-trash-alt"
									style="font-size: 14px;"
									title="Elimină din comandă"
								></i>
							</div>
							<div
								v-show="cartItem.qty > 1"
								@click="minusQty(cartItem.id)"
								class="qty-minus"
								title="Micșorează cantitatea"
							>
								&ndash;
							</div>
							<!-- show qty -->
							<div class="qty">{{ cartItem.qty }}</div>
							<!-- btn plus qty -->
							<div
								@click="plusQty(cartItem.id)"
								class="qty-plus"
								title="Mărește cantitatea"
							>
								+
							</div>
						</span>
						<!-- price -->
						<span style="padding: 0 0 4px 10px;">
							<span class="is-size-6"> x </span>
							<!-- product price -->
							<span class="has-text-weight-normal">{{
								cartItem.customProductPrice
							}}</span>
							<!-- subtotal if qty > 1-->
							<span class="is-size-5 has-text-weight-normal">
								=
								{{ cartItem.priceTotal | dotToComma }} lei</span
							>
							<!-- <span v-if="cartItem.qty > 1" class="is-size-5 has-text-weight-normal"> = {{cartItem.priceTotal | dotToComma}} lei</span> -->
							<!-- <span v-else> lei</span> -->
						</span>
					</div>
				</div>
			</div>

			<!-- subtotal & pret livrare -->
			<div
				v-if="
					parseFloat($store.getters.subtotal) <
						$store.getters.totalToPay
				"
				class="is-size-5 has-text-weight-semibold has-text-centered static-grey-box"
			>
				<!-- subtotal -->
				<div>
					Subtotal: {{ $store.getters.subtotal | dotToComma }} lei
				</div>

				<!-- livrare -->
				<div v-if="$store.state.company.pret_livrare">
					<!-- minim gratuit -->
					<div v-if="$store.state.company.minim_livrare_gratuita">
						<!-- sub minim gratuit -->
						<div
							v-if="
								parseFloat($store.getters.subtotal) <
									parseFloat(
										$store.state.company
											.minim_livrare_gratuita
									)
							"
							style="margin-top: 5px;"
						>
							<!-- cost livrare -->
							+ livrare:
							{{
								parseFloat(
									$store.state.company.pret_livrare
								).toFixed(2) | dotToComma
							}}
							lei
							<!-- free if > minim -->
							<div class="is-size-7 has-text-weight-normal">
								* livrare gratuită pentru comenzi<br />
								mai mari de
								<b
									>{{
										$store.state.company
											.minim_livrare_gratuita
									}}
									lei</b
								>
							</div>
						</div>
					</div>
					<!-- livrare standard -->
					<div v-else>
						+ livrare:
						{{
							parseFloat(
								$store.state.company.pret_livrare
							).toFixed(2) | dotToComma
						}}
						lei
					</div>
				</div>
			</div>

			<!-- TOTAL -->
			<div
				class="is-size-4 has-text-weight-semibold has-text-centered static-grey-box"
			>
				<!-- <div class="is-hidden-mobile is-size-4 has-text-weight-semibold has-text-centered static-grey-box"> -->
				<!-- total -->
				TOTAL: {{ $store.getters.totalToPay | dotToComma }} lei
				<!-- plata -->
				<div class="is-size-6">
					<span class="has-text-weight-light">PLATA: </span>

					<!-- numerar -->
					<span v-if="$store.state.company.numerar === '1'"
						>numerar</span
					>
					<!-- slash daca am numerar si card -->
					<span
						v-if="
							$store.state.company.numerar === '1' &&
								$store.state.company.card === '1'
						"
						>/</span
					>
					<!-- card -->
					<span v-if="$store.state.company.card === '1'">card</span>
					<!-- (la livrare) daca am plata numerar sau card -->
					<span
						v-if="
							$store.state.company.numerar === '1' ||
								$store.state.company.card === '1'
						"
					>
						(la livrare)</span
					>
					<span
						v-if="
							($store.state.company.numerar === '1' ||
								$store.state.company.card === '1') &&
								$store.state.company.transfer === '1'
						"
						>,
					</span>
					<span v-if="$store.state.company.transfer === '1'"
						>transfer bancar</span
					>
				</div>
				<!-- msg livrare gratuita -->
				<div
					v-if="
						parseFloat($store.getters.subtotal) ==
							$store.getters.totalToPay
					"
					class="is-size-6 has-text-weight-light"
				>
					(livrare gratuită)
				</div>
			</div>
			<br />

			<!-- btn msg comanda minima -->
			<a
				v-if="
					parseFloat($store.getters.subtotal) <
						$store.state.company.comanda_minima
				"
				class="button is-danger is-outlined is-fullwidth has-text-danger has-background-white has-text-weight-bold"
			>
				Comanda minimă: {{ $store.state.company.comanda_minima }} lei
			</a>
			<!-- btn deschide modal formular date client -->
			<div v-else>
				<!-- nu primim comenzi -->
				<button
					v-if="$store.state.company.nu_primim_comenzi === '1'"
					class="button is-fullwidth is-danger has-text-weight-bold"
				>
					MOMENTAN, NU PRIMIM COMENZI!
				</button>

				<!-- btns desktop/mobile -->
				<div v-else>
					<!-- desktop btn > add to history -->
					<button
						@click="$router.push('/trimite-comanda')"
						class="is-hidden-touch button is-fullwidth is-danger has-text-weight-bold"
					>
						UNDE LIVRĂM?
					</button>
					<!-- mobile btn > no history -->
					<button
						@click="$router.replace('/trimite-comanda')"
						class="is-hidden-desktop button is-fullwidth is-danger has-text-weight-bold"
					>
						UNDE LIVRĂM?
					</button>
				</div>
			</div>

			<!-- custom mesage -->
			<div
				v-if="$store.state.company.mesaj_personalizat"
				class="is-size-7 is-italic has-text-centered static-grey-box mt-20"
			>
				{{ $store.state.company.mesaj_personalizat }}
			</div>
		</div>
	</div>
</template>
<script>
import store from "@/store/index";

export default {
	name: "Cart",
	filters: {
		dotToComma: (string) => string.replace(".", ","),
		// dotToComma: string => string.replace(/./g, ",") // inlocuieste pi tăti
	},
	methods: {
		// open product to modify
		openProductModal(cartProduct) {
			// console.log(cartProduct);

			// don't run on shop version 0
			if (this.$store.state.company.shop_version == 0) {
				return;
			}

			this.$store.commit("SET_BTN_ADAUGA_ACTUALIZEAZA_COS", true); // change btn & action on modal
			this.$store.commit("PRODUCT_MODAL", true); // open modal
			this.$store.commit(
				"SET_CUSTOM_PRODUCT_PRICE",
				cartProduct.productPrice
			); // first price
			this.$store.commit("MODAL_LOAD_PRODUCT_TO_MODIFY", cartProduct); // set customProduct data in store:
			this.$store.dispatch("fetchProduct", cartProduct.product_id); // fetch all product data
		},

		plusQty(id) {
			this.$store.commit("PLUS_QTY", id);
		},

		minusQty(id) {
			this.$store.commit("MINUS_QTY", id);
		},

		deleteCartProduct(id) {
			this.$store.commit("DELETE_CART_PRODUCT", id); // console.log(id);
		},
	},
	mounted() {
		// delete products from other shops
		let filteredCart = this.$store.state.cart.filter(
			(e) =>
				JSON.parse(e).company_id === this.$store.state.company.user_id
		);
		this.$store.commit("REPLACE_CART", filteredCart);
	},
};
</script>
<style scoped>
@media only screen and (min-width: 1024px) {
	#cart-container {
		position: -webkit-sticky;
		position: sticky;
		top: 140px;
	}
}
.grey-box {
	background-color: #f5f5f5;
	border: 1px solid #f5f5f5;
	border-radius: 6px;
	margin-top: 10px;
	padding: 15px;
	height: 100%;
	position: relative;
	transition: all 0.3s;
}
.grey-box:hover {
	color: #000;
	background-color: #fbfbfb;
	border: 1px solid #ececec;
}
.static-grey-box {
	background-color: #f5f5f5;
	border: 1px solid #f5f5f5;
	border-radius: 6px;
	margin-top: 10px;
	padding: 15px;
	height: 100%;
	position: relative;
}
.edit-icon {
	margin: 0 7px 0 0;
	font-size: 14px;
}
.grey-box:hover .edit-icon {
	color: #000 !important;
}
.delete-item {
	position: absolute;
	top: 7px;
	right: 7px;
}

/* NEW */
.qty-container {
	height: 25px;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	line-height: 13px;
	border: 1px solid #ddd;
	border-radius: 4px;
}
.qty-minus,
.qty-plus {
	width: 30px;
	text-align: center;
	padding-top: 3px;
	font-size: 20px;
	cursor: pointer;
}
.qty-minus-elimina {
	padding: 5px 9px;
	cursor: pointer;
}
.qty {
	padding: 5px 0;
	font-weight: normal;
}

@media only screen and (max-width: 1024px) {
	.qty-container {
		font-size: 20px;
		height: 40px;
	}
	.qty-minus,
	.qty-plus {
		cursor: pointer;
		padding: 12px 14px;
		width: 40px;
	}
	.qty-minus-elimina {
		padding: 10px 12px 0 12px;
		cursor: pointer;
	}
	.qty {
		padding-top: 12px;
		font-weight: normal;
	}
}
</style>
