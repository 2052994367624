export default { // = computed properties

	isOwner: state => {
	
		if(state.company.user_id === state.user.user_id) {

			state.company = state.user; // auth user
			// TODO
			// can view orders
			return true;

		} else {

			// TODO
			// cannot view orders
			return false;
		}
	},
	// reversedPastOrders: state => {
	// 	if(Array.isArray(state.pastOrders)) {

	// 		return state.pastOrders.slice().reverse();

	// 	} else {

	// 		return state.pastOrders;

	// 	}
	// },
	getSelectedCategoryName: state => {
		return state.selectedCategory.category_name;
	},
	products (state, getters) {

		// all products -> auth user
		if(getters.isOwner) {

			return state.products;
		
		// only available products -> clients
		} else {
			
			// filter if only exists
			if(state.products) {
				
				return state.products.filter(product => product.unavailable == 0);
			
			} else {

				return state.products;

			}
		}
	},

	editProduct: (state) => {		

		if(state.editProductId) {
			return state.products.find(product => product.product_id === state.editProductId);
		}

	},

	// EXAMPLE: selected order data by id
	// orderData: (state) => (id) => {
	// 	return state.orders.find(item => item.order_id == id);
	// },

	

	// bla: () => console.log('bla from getters'),

	// eliminat!!! vin sortate din db
	// sortedCategories: state => { 
	// 	// if(state.categories.length > 0) {
	// 		state.categories.sort((a, b) => (a.category_order - b.category_order)) 
	// 	// }
	// },

	// must sort array cart dupa data adaugarii 
	// la update value din cart of an array the state is not reactive
	// so must delete that product object from array and reinsert after modified
	// example mutation PLUS_QTY, MINUS_QTY  
	sortedCart: state => {
		// new empty array
		let newCart = [];
		// loop in object & push to array
		for (let val of Object.values(state.cart)) {
			newCart.push(JSON.parse(val));
		}
		// sort the new array
		return newCart.sort((a,b) => a.id - b.id);

	},

	subtotal: state => {

		if(state.cart.length > 0) {
		
			// map array
			let arrayPrices = state.cart.map(totalProduct => JSON.parse(totalProduct).priceTotal);

			// reduce array
			let totalToPay = arrayPrices.reduce((accumulator, amount) => parseFloat(accumulator) + parseFloat(amount));

			return parseFloat(totalToPay).toFixed(2);

		}
	  
	},

	totalToPay: (state, getters) => {

		if(state.cart.length > 0) {

			let subtotal = parseFloat(getters.subtotal);
		
			// cu cost livrare
			if(state.company.pret_livrare) {

				let pret_livrare = parseFloat(state.company.pret_livrare);

				// if minim livrare gratuita
				if(state.company.minim_livrare_gratuita) {

					let minim_livrare_gratuita = parseFloat(state.company.minim_livrare_gratuita);

					// a) sub minim_livrare_gratuita => add pret livrare
					if(subtotal < minim_livrare_gratuita) {

						let totalToPay = pret_livrare + subtotal;
						return totalToPay.toFixed(2);

					// b) peste minim => livrare gratuita
					} else {

						return subtotal.toFixed(2);
					}				

				// fara minim livrare gratuita
				} else {

					let totalToPay = pret_livrare + subtotal;
					return totalToPay.toFixed(2);
					
				}

			// fara cost livrare -> totalToPay = subtotal
			} else {

				return parseFloat(subtotal).toFixed(2);

			}

		}
	  
	},

	// cart: state => state.cart,

	// from ProductModal.vue
	totalProduct: state => {

		// animate @ total price changed
		if(document.getElementById('addToCartBtn')) {
			
			let btn = document.getElementById('addToCartBtn');
			btn.classList.add('animateBkg');

			setTimeout(() => {
				btn.classList.remove('animateBkg');
			}, 200);
			
		}
		
		// product price
		let productPrice = parseFloat(state.customProduct.productPrice); 

		// topping price
		let priceTopping = 0;
		if(state.customProduct.topping.length > 0) {
			
			priceTopping = state.customProduct.topping.reduce((accumulator, n) => accumulator + parseFloat(n.topping_price), 0);                
		
		}

		// options price
		let priceOptions = 0;
		if(state.customProduct.options.length > 0) {
							
			priceOptions = state.customProduct.options.reduce((accumulator, n) => accumulator + parseFloat(n.variant_price), 0);                

		}

		// customized product: product + topping + options .toFixed(2)
		let productToppingOptionsPrice = productPrice + priceTopping + priceOptions;
		state.customProduct.customProductPrice = productToppingOptionsPrice.toFixed(2);
		state.customProduct.customProductPrice = productToppingOptionsPrice.toFixed(2);
		
		// console.log(this.customizedProduct.customProductPrice);

		// total: product * qty
		let total = parseFloat(state.customProduct.customProductPrice * state.customProduct.qty);

		state.customProduct.priceTotal = total.toFixed(2);

		// return customizedProductTotalPrice;
		return state.customProduct.priceTotal;

		
	},

	getAppRoute: (state) => {
		if(window.location.hostname.match(/livrari.eu/)) {
			return '/';
		} else {
			return '/';
			// return '/' + state.company.url;
		}
	},


	

	// fnction get selected category from categories

	// getOptions: state => state.toppings,// https://www.w3schools.com/jsref/jsref_sort.asp
	// getSelectedCategory: () => { state.categories.find(selectedCategory => categories.category_id == state.selectedCategory), console.log('asdf');
	// },  

	// getActionResult: state => state.actionResult,

	// showErrorMsg(containerId) {
	//   let container = document.getElementById(containerId);
	//   // container.innerHTML = '<span class="icon has-text-primary"><i class="fas fa-lg fa-shopping-cart"></i></span>';
	//   setTimeout(() => {
	//       container.innerHTML = '';
	//   }, 2000);
	// },

	// categories: (state) => state.categories,
	// categoriesCount: (state) => state.categories.length,
	// selectedCategory: (state) => state.selectedCategory,
	  // products: (state) => state.products,
	  // userStatus: (state) => state.userStatus,
	

	// isLoggedIn: state => !!state.token,
	// authStatus: state => state.status,
	// selectedCategoryName (state, getters) {
	//   return state.categories.find(cat => cat.category_id === '29')
	// }
	// url: (state) => state.url
	// productsCount () {
	//   // ....
	// },
	// availableProducts (state, getters) {
	//   return state.products.filter(product => product.inventory > 0)
	// }
  }