<template>
    <div
        v-if="$store.state.modalConfirm"
            @click="$store.commit('SET_MODAL_CONFIRM', '')"
            class="modalBkg"
        >
        <div class="modalBox">
            <a @click="$store.commit('SET_MODAL_CONFIRM', '')" class="delete is-pulled-right"></a>

            <div :class="computedClass" class="modalBoxText">
                
                <!-- icon -->
                <span class="icon is-large">
                    <i v-if="$store.state.modalConfirm.type === 'success'" class="fas fa-2x fa-thumbs-up"></i>
                    <i v-else-if="$store.state.modalConfirm.type === 'error'" class="fas fa-2x fa-exclamation-triangle"></i>
                    <i v-else class="fas fa-2x fa-info-circle"></i>
                </span>

                <!-- msg -->
                <div style="margin-top: 20px; white-space: normal;">
                    {{$store.state.modalConfirm.msg}}
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
export default {
    name: 'ModalConfirm',
    computed: {
        computedClass() {

            let className = 'has-text-info'; // default
            // success
            if(this.$store.state.modalConfirm.type === 'success') {
                className = 'greenText';
            // error
            } else if(this.$store.state.modalConfirm.type === 'error') {
                className = 'redText';
            }
            return className;
        }
    }
}
</script>
<style scoped>
    .modalBkg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
        display:flex;
        flex-direction: column;
        align-items: center;
        background: #00000030;
        cursor: pointer;
    }
    .modalBox {
        background: #FFF;
        z-index: 99999 !important;
        margin: 10% 15px 15px 15px;
        padding:10px;
        border-radius: 10px;
        max-width: 600px;
        width: 95%;
        cursor: default;
    }
    .modalBoxText {
        text-align: center;
        padding: 40px 10px 60px;
        flex-wrap: wrap;
    }
    .redText {
        color: #e20000;
    }
    .greenText {
        color: green;
    }

    @media screen and (min-width: 768px) {
        .modalBoxText {
            padding: 50px 100px 60px; 
        }        
    }
</style>