<template>
    <div id="header-menu-comenzi-bar">
        <div id="categories-container">

            <!-- menu comenzi -->
            <div id="scroll-div-container">
                <div id="scroll-div">
                    
                    <!-- comenzi noi -->
                    <div class="category-item is-flex">
                        <div class="is-flex">
                            <a
                                @click="$store.dispatch('fetchOrders', { orderStateId: 1, page: 1})"
                                class="menu-item"
                                :class="$store.state.selectedOrderState === 1 ? 'has-text-primary has-border-bottom' : 'has-text-grey'"
                            >
                                Comenzi noi <span v-if="$store.state.newOrders">({{$store.state.newOrders}})</span>
                            </a>
                        </div>
                    </div>

                    <!-- comenzi in lucru -->
                    <div class="category-item is-flex">
                        <div class="is-flex">
                            <a
                                @click="$store.dispatch('fetchOrders', { orderStateId: 2, page: 1})"
                                class="menu-item"
                                :class="$store.state.selectedOrderState === 2 ? 'has-text-primary has-border-bottom' : 'has-text-grey'"
                            >
                                Comenzi în lucru
                            </a>
                        </div>
                    </div>

                    <!-- comenzi in livrare -->
                    <div class="category-item is-flex">
                        <div class="is-flex">
                            <a
                                @click="$store.dispatch('fetchOrders', { orderStateId: 3, page: 1})"
                                class="menu-item"
                                :class="$store.state.selectedOrderState === 3 ? 'has-text-primary has-border-bottom' : 'has-text-grey'"
                            >
                                Comenzi în livrare
                            </a>
                        </div>
                    </div>

                    <!-- comenzi livrate -->
                    <div class="category-item is-flex">
                        <div class="is-flex">
                            <a
                                @click="$store.dispatch('fetchOrders', { orderStateId: 4, page: 1})"
                                class="menu-item"
                                :class="$store.state.selectedOrderState === 4 ? 'has-text-primary has-border-bottom' : 'has-text-grey'"
                            >
                                Comenzi livrate
                            </a>
                        </div>
                    </div>

                    <!-- comenzi anulate -->
                    <div class="category-item is-flex">
                        <div class="is-flex">
                            <a
                                @click="$store.dispatch('fetchOrders', { orderStateId: 5, page: 1})"
                                class="menu-item"
                                :class="$store.state.selectedOrderState === 5 ? 'has-text-primary has-border-bottom' : 'has-text-grey'"
                            >
                                Comenzi anulate
                            </a>
                        </div>
                    </div>
                </div>
                
                <!-- scroll left btn -->
                <span @click="scrollLeft()" class="scroll-buttons is-hidden" style="display: flex; position: absolute; left: 0;">
                    <span class="icon has-text-primary arrow-icons">
                        <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="span-gradient" style="background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);">
                    </span>
                </span>

                <!-- scroll right btn -->
                <span @click="scrollRight()" class="scroll-buttons is-hidden" style="display: flex; position: absolute; right: 0;">
                    <span class="span-gradient" style="background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);">
                    </span>
                    <span class="icon has-text-primary arrow-icons">
                        <i class="fas fa-chevron-right"></i>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'HeaderMenuBar',
    methods: {
        scrollLeft() {
            document.getElementById('scroll-div').scrollLeft -= 130;
        },
        scrollRight() {
            document.getElementById('scroll-div').scrollLeft += 130;
        },
        showScrollButtons() {
            if(document.getElementById("scroll-div")) {
                let scrollDiv = document.getElementById("scroll-div");
                let scrollButtons = document.getElementsByClassName("scroll-buttons");
                if (scrollDiv.scrollWidth > scrollDiv.offsetWidth) {
                    scrollButtons[0].classList.remove('is-hidden');
                    scrollButtons[1].classList.remove('is-hidden');
                }
            }
        },
        scrollEnd() {
            if(document.getElementById("scroll-div")) {
                let scrollDiv = document.getElementById("scroll-div");
                scrollDiv.scrollLeft += 2000;
            }
        }

    },
    mounted() {
        // if(this.$store.state.categories) {
            this.showScrollButtons();
        // }
    },
    // updated() {
    //     if(this.$store.state.categories) {
    //         this.showScrollButtons();
    //     }        
    // }
    
}
</script>

<style scoped>
    #categories-container {
        display: flex;
        justify-content: center;
        width: 100%;
        border-top: 1px solid #EEE;
    }
    #scroll-div-container {
        padding: 0;
        position: relative;
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
    }
    #scroll-div {
        padding: 2px 15px !important;
        margin: 0 20px;
        display: flex;
        overflow-y:auto;
        white-space: nowrap;
        scroll-behavior: smooth;
    }
    .arrow-icons {
        width: 1.5rem !important;
        height: 2.8rem;
        background-color: #FFF;
    }
    .span-gradient {
        height:2.8rem;
        width: 1rem;
    }
    .scroll-buttons {
        cursor: pointer;
    }

    #header-menu-comenzi-bar {
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu-item {
        padding: 10px 15px !important;
        display: inline-block;
        white-space:nowrap;
    }

    .edit-category-icon {
        padding: 23px 5px 20px 0;
        margin-right: 10px;
        color: #ffbcbc;
        cursor: pointer;
    }
    .edit-category-icon:hover {
        color: #ff4444;
    }
    .tabs a {
        color: #999;
    }
    .tab-activ a {
        border-bottom: 1px solid #666;
        color: #666;
    }

    .edit-categories-container {
        position: fixed;
        top: 135px;
        border: 1px solid #EEE;
        border-radius: 3px;
        padding: 10px 10px 25px 10px;
        background-color: #FFF;
    }

    @media only screen and (min-width: 760px) {
        .edit-categories-container {
            top: 140px;
            padding: 10px 30px 25px 30px;
        }
    }
    @media only screen and (min-width: 1024px) {
        /* Hide scrollbar for Chrome, Safari and Opera */
        #scroll-div-container::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE and Edge */
        #scroll-div-container {
            -ms-overflow-style: none;
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        #scroll-div::-webkit-scrollbar {
            display: none;
        }
        /* Hide scrollbar for IE and Edge */
        #scroll-div {
            -ms-overflow-style: none;
        }
    }
    @media only screen and (min-width: 769px) {
        .edit-categories-container {
            top: 150px;
        }
    }
    @media only screen and (min-width: 1407px) {
        .edit-categories-container {
            top: 165px;
        }
    }
</style>