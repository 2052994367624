<template>
  <div>
    <!-- loading products -->
    <div
      v-if="$store.state.loadingProducts"
      style="height: 20vh; text-align: center;"
    >
      <span class="icon is-large  has-text-primary">
        <i class="fas fa-2x fa-spinner fa-pulse"></i>
      </span>
    </div>

    <!-- no categories => new account/empty shop -->
    <div v-else-if="!$store.state.categories">
      <!-- admin - configurare magazin -->
      <div
        v-if="$store.getters.isOwner"
        class="has-text-grey is-size-6 has-text-centered"
      >
        <div class="is-size-4 has-text-weight-light">CONFIGURARE MAGAZIN</div>
        <br />
        <div>
          Click pe simbolul
          <span class="icon">
            <i
              class="far fa-user is-size-5 is-size-6-touch has-text-primary"
            ></i>
          </span>
          (colț dreapta sus) pentru a configura magazinul tău:
        </div>

        <div
          style="max-width: 800px; margin:20px auto; text-align: left; line-height: 35px;"
        >
          <div style="display: flex;">
            <span class="has-text-primary is-size-4" style="margin-right: 10px;"
              >1.</span
            >
            Inserează un preț pentru comanda minimă, pentru livrare sau pentru o
            comandă minimă cu livrare gratuită.
          </div>
          <div style="display: flex;">
            <span class="has-text-primary is-size-4" style="margin-right: 10px;"
              >2.</span
            >
            Completează orarul și localitatea/localitățile când/unde poți face
            livrări și selecteaza metodele de plată disponibile pentru clienții
            tăi: numerar la livrare, card (dacă ai POS mobil) la livrare sau
            transfer bancar (trebuie să completezi IBAN-ul tău).
          </div>
          <div style="display: flex;">
            <span class="has-text-primary is-size-4" style="margin-right: 10px;"
              >3.</span
            >
            Adaugă și datele tale de contact (adresă, telefon) precum și datele
            firmei tale (CUI. nr. Reg. Com, adresă)
          </div>
          <div style="display: flex;">
            <span class="has-text-primary is-size-4" style="margin-right: 10px;"
              >4.</span
            >
            Inserează categoriile de produse (apoi produsele) pentru care vrei
            să primești comenzi.
          </div>
        </div>

        <!-- btn adauga prima categorie -->
        <div class="box" style="max-width: 700px; margin: 40px auto;">
          <div class="is-size-5 has-text-weight-light">
            ADAUGĂ PRIMA CATEGORIE DE PRODUSE:
          </div>
          <!-- form add category -->
          <div class="has-text-centered">
            <br />
            <input
              class="input"
              id="firstCategoryInput"
              type="text"
              v-model="numeCategorie"
              placeholder="Ex.: Pizza, Paste, Desert"
              maxlength="50"
              style="max-width: 250px;"
            />
            <div>
              <button
                @click="$store.dispatch('addCategory', numeCategorie)"
                class="button is-small is-success is-outlined"
                style="margin:20px 8px;"
                title=" categorie"
              >
                Salvează
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- clients in empty shop -->
      <div
        v-else
        class="has-text-danger has-text-centered has-text-weight-light is-size-5"
      >
        <br />Încă nu au fost inserate categorii/produse
      </div>
    </div>

    <div v-else>
      <div>
        <!-- no products -->
        <div
          v-if="
            ($store.getters.products && $store.getters.products.length == 0) ||
              !$store.getters.products
          "
        >
          <div
            class="has-text-danger has-text-weight-medium"
            id="msg-no-products"
          >
            Nu există produse în categoria selectată
          </div>
        </div>

        <!-- <div>Momentan, nu se pot trimite comenzi.</div> -->

        <!-- start products grid -->
        <div class="masonry">
          <!-- start product card item -->
          <div
            v-for="product in $store.getters.products"
            v-bind:key="product.id"
            :id="'product_' + product.product_id"
            class="item"
            :class="{ 'unavailable-product': product.unavailable == '1' }"
          >
            <!-- badge produs indisponibil -->
            <div
              v-if="product.unavailable == '1'"
              class="produs-indisponibil-badge has-background-danger has-text-white"
            >
              Produs indisponibil
            </div>
            <!-- fotografie produs -->
            <div v-if="product.product_photo" class="product-image-container">
              <img
                :src="
                  $store.state.appUrl +
                    '/img/' +
                    $store.state.company.user_id +
                    '/' +
                    product.product_photo
                "
                :alt="
                  `${product.product_name}, livrare la domiciliu in ${$store.state.company.localitati_livrare}`
                "
                :title="
                  `${product.product_name}, livrare la domiciliu in ${$store.state.company.localitati_livrare}`
                "
                loading="lazy"
              />
            </div>
            <!-- fara fotografie -->
            <div v-else class="mobile-product-no-photo">Fără fotografie</div>
            <!-- nume -->
            <h2
              :title="
                `${product.product_name}, livrare la domiciliu in ${$store.state.company.localitati_livrare}`
              "
              class="is-size-4 has-text-centered"
            >
              {{ product.product_name }}
            </h2>
            <!-- descriere -->
            <div class="is-size-7">{{ product.product_ingredients }}</div>

            <!-- footer common mobile/desktop, 0 / shop version 0 -->
            <footer v-if="$store.state.company.shop_version == 0">
              <!-- price & add cart btn -->
              <div
                class="card-footer card-footer-shop-v0 is-flex justify-content-space-around align-items-center"
              >
                <!-- show price -->
                <span class="is-flex align-items-center has-text-primary">
                  <span class="has-text-weight-semibold is-size-5 margin-5">{{
                    product.price_one_value | dotToComma
                  }}</span>
                  <span>lei</span>

                  <!-- optiune pret -->
                  <span>{{ product.price_option }}</span>
                </span>

                <!-- btn edit product if authenticated -->
                <a
                  v-if="$store.getters.isOwner"
                  @click="setEditProduct(product.product_id)"
                  class="button is-danger is-outlined"
                  title="Modifică produs"
                >
                  <span>
                    <i class="far fa-edit" style="font-size:22px;"></i>
                  </span>
                </a>

                <!-- btn add to cart -->
                <div v-else>
                  <div>
                    <!-- btns for product already in cart -->
                    <div
                      v-if="
                        productInCart(product.product_id) &&
                          productInCart(product.product_id).qty > 0
                      "
                      class="btnsQtyContainer has-text-primary"
                    >
                      <!-- btn minus/trash qty -->
                      <div
                        v-show="productInCart(product.product_id).qty === 1"
                        @click="
                          deleteCartProduct(
                            productInCart(product.product_id).productInCartId
                          )
                        "
                        class="btnMinusElimina"
                        title="Elimină din comandă"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </div>
                      <div
                        v-show="productInCart(product.product_id).qty > 1"
                        @click="
                          minusQty(
                            productInCart(product.product_id).productInCartId
                          )
                        "
                        class="btnMinus"
                        title="Micșorează cantitatea"
                      >
                        <i class="fa fa-minus" title="Micșorează cantitate"></i>
                      </div>

                      <!-- qty -->
                      <div class="btnQty">
                        {{ productInCart(product.product_id).qty }}
                      </div>

                      <!-- plus -->
                      <div
                        @click="
                          plusQty(
                            productInCart(product.product_id).productInCartId
                          )
                        "
                        class="btnPlus"
                        title="Mărește cantitatea"
                      >
                        <i class="fa fa-plus" title="Micșorează cantitate"></i>
                      </div>
                    </div>

                    <!-- not in cart -->
                    <div v-else>
                      <a
                        @click="
                          addToCartV_0(
                            product.product_id,
                            product.product_name,
                            product.price_one_value,
                            product.price_option
                          )
                        "
                        class="button button-add-to-cart-v0 is-primary is-outlined"
                        title="Adaugă în coș"
                      >
                        <span class="cartPlusIconContainer">
                          <i class="fa fa-cart-plus cartPlusIcon"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- ORIGINAL: btn add to cart (!authenticated) -->
                <!-- <a v-else @click="addToCartV_0(product.product_id, product.product_name, product.price_one_value, product.price_option)" class="button button-add-to-cart-v0 is-primary is-outlined" title="Adaugă în coș" :class="{'unavailable-product' : product.unavailable == 1}">
                                    <span v-if="isInCart(product.product_id)" class="productsInCart">
                                        <span class="is-size-7">x </span>{{productInCart(product.product_id)}}
                                    </span>
                                    <span class="cartPlusIconContainer">
                                        <i class="fa fa-cart-plus cartPlusIcon"></i>
                                    </span>
                                </a> -->
                <!-- END ORIGINAL -->
              </div>
            </footer>

            <!-- footer 1 shop version 1 -->
            <footer
              v-else
              class="card-footer is-flex justify-content-space-around"
            >
              <!-- has multiple prices -->
              <div
                v-if="product.price_two_value != ''"
                class="dropdown is-up is-hoverable"
              >
                <div class="dropdown-trigger">
                  <a
                    @click="
                      openProductModal(
                        product.product_id,
                        product.price_one_value,
                        product.price_one_name
                      )
                    "
                    class="button buton-multiple-price is-primary is-outlined is-text-6"
                    aria-haspopup="true"
                    aria-controls="show-all-prices"
                    title="Deschide"
                    style="margin-top:10px;"
                  >
                    <span>
                      <span style="word-spacing: -2px; margin-right: 4px;"
                        >de la:</span
                      >
                      <span class="has-text-weight-semibold is-size-5">{{
                        product.price_one_value | dotToComma
                      }}</span>
                      lei
                    </span>
                  </a>
                </div>
                <div
                  class="dropdown-menu"
                  id="show-all-prices"
                  role="menu"
                  style="width: 222px;"
                >
                  <div
                    class="dropdown-content"
                    style="border: 1px solid green;"
                  >
                    <div class="dropdown-item has-text-primary is-size-6">
                      <div>
                        &rsaquo; {{ product.price_one_name }}:
                        <span class="is-size-5 has-text-weight-semibold">{{
                          product.price_one_value | dotToComma
                        }}</span>
                        lei
                      </div>
                      <div>
                        &rsaquo; {{ product.price_two_name }}:
                        <span class="is-size-5 has-text-weight-semibold">{{
                          product.price_two_value | dotToComma
                        }}</span>
                        lei
                      </div>
                      <div v-if="product.price_three_value">
                        &rsaquo; {{ product.price_three_name }}:
                        <span class="is-size-5 has-text-weight-semibold">{{
                          product.price_three_value | dotToComma
                        }}</span>
                        lei
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- only one price -->
              <span v-else class="has-text-primary" style="padding: 10px 5px;">
                <a
                  @click="
                    openProductModal(
                      product.product_id,
                      product.price_one_value,
                      product.price_one_name
                    )
                  "
                  class="button is-outlined is-primary no-border"
                  title="Deschide"
                >
                  <span
                    class="has-text-weight-semibold is-size-5"
                    style="margin-right: 4px;"
                  >
                    {{ product.price_one_value | dotToComma }}</span
                  >
                  lei
                </a>
              </span>
              <!-- open modal product detalii -->
              <span
                class="has-text-primary is-bordered"
                style="padding: 10px 5px;"
              >
                <a
                  @click="
                    openProductModal(
                      product.product_id,
                      product.price_one_value,
                      product.price_one_name
                    )
                  "
                  class="button is-outlined is-primary no-border"
                  title="Deschide"
                  >Detalii</a
                >
              </span>
            </footer>
          </div>
          <!-- end product card -->
        </div>
        <!-- end products container -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import store from '@/store/index';

export default {
  name: "ProductsGrid",
  components: {
    // CumparaAbonament
  },
  data() {
    return {
      qty: 1,
      qty_selectedProduct: 1,
      modalData: {
        // opened: false,
        fetchProductData: false,
        // selectedProduct: ''
      },

      numeCategorie: "",
    };
  },
  methods: {
    // isInCart(product_id) {
    //     if(this.$store.state.cart.find(obj => JSON.parse(obj).product_id === product_id)) {
    //         return true;
    //     }
    // },

    productInCart(product_id) {
      let productInCart = this.$store.state.cart.filter(
        (obj) => JSON.parse(obj).product_id === product_id
      )[0]; // console.log(productInCart);

      if (productInCart !== undefined) {
        // return JSON.parse(productInCart).qty;

        let obj = {
          qty: JSON.parse(productInCart).qty,
          productInCartId: JSON.parse(productInCart).id,
        };
        return obj;
      }
    },

    plusQty(id) {
      this.$store.commit("PLUS_QTY", id);
    },

    minusQty(id) {
      this.$store.commit("MINUS_QTY", id);
    },

    deleteCartProduct(id) {
      this.$store.commit("DELETE_CART_PRODUCT", id); // console.log(id);
    },

    setEditProduct(productId) {
      this.$store.commit("SET_EDIT_PRODUCT_ID", productId); // set id product to edit
      this.$router.push("/modifica-produs");
      // this.$store.commit('SET_EDIT_PRODUCT_MODAL', true); open modal edit product
    },

    toggleZoomClass() {
      let img = event.target;
      img.classList.toggle("mobile-product-photo-large");
    },
    iconToggleZoomClass() {
      let img = event.target.previousSibling;
      img.classList.toggle("mobile-product-photo-large");
    },
    showDeleteConfirm() {
      let clickedBtn = event.target;
      let container = clickedBtn.closest(".admin-btns-container");
      container.classList.add("is-hidden");
      container.nextSibling.classList.remove("is-hidden");
    },
    showAdminBtns() {
      let clickedBtn = event.target;
      let container = clickedBtn.closest(".confirm-delete-btns-container");
      container.classList.add("is-hidden");
      container.previousSibling.classList.remove("is-hidden");
    },
    toggleEditProductBtnsContainer() {
      let clickedBtn = event.target.closest(".button");
      clickedBtn.nextSibling.classList.toggle("is-hidden");
    },
    toggleClass(elementId, className) {
      let element = document.getElementById(elementId);
      element.classList.toggle(className);
    },
    openProductModal(productId, productPrice, productPriceName) {
      // don't run on shop version 0
      if (this.$store.state.company.shop_version == 0) {
        return;
      }

      this.$store.commit("PRODUCT_MODAL", true);
      this.$store.commit("SET_CUSTOM_PRODUCT_PRICE", productPrice);
      this.$store.commit("SET_CUSTOM_PRODUCT_PRICE_NAME", productPriceName);

      this.$store.commit("SET_LOADING_PRODUCT", false);
      this.$store.commit(
        "SET_PRODUCT",
        this.products.find((products) => products.product_id === productId)
      );
    },

    // hideProduct(id) {

    // },

    // shop version 0 only
    // plusQty(id) {

    //     let qtyContainer = document.getElementById('qtyContainer_' + id);
    //     let initialQty = qtyContainer.innerText;
    //     let increasedQty = parseInt(initialQty) + 1;
    //     qtyContainer.innerText = increasedQty;
    //     // store new qty
    //     this.qty_selectedProduct = increasedQty;

    // },
    // minusQty(id) {

    //     let qtyContainer = document.getElementById('qtyContainer_' + id);
    //     let initialQty = qtyContainer.innerText;

    //     if(initialQty > 1) {

    //         let decreasedQty = parseInt(initialQty) - 1;
    //         qtyContainer.innerText = decreasedQty;
    //         // store new qty
    //         this.qty_selectedProduct = decreasedQty;
    //     }
    // },
    addToCartV_0(productId, productName, productPrice, price_option) {
      let btn = event.target.closest(".button");
      btn.classList.add("is-loading");

      this.$store.state.customProduct.company_id = this.$store.state.company.user_id;
      this.$store.state.customProduct.product_id = productId;
      this.$store.state.customProduct.product_name = productName;
      this.$store.state.customProduct.productPrice = productPrice;
      this.$store.state.customProduct.price_option = price_option;
      this.$store.state.customProduct.customProductPrice = productPrice;
      this.$store.state.customProduct.qty = this.qty_selectedProduct;
      this.$store.state.customProduct.productPrice = productPrice;
      this.$store.state.customProduct.priceTotal = (
        productPrice * this.qty_selectedProduct
      ).toFixed(2);
      this.$store.state.customProduct.id = Date.now(); // add time for use in sorting product after increase/decrease qty (need to delete entire row from obj. because is not reactive)
      this.$store.commit("ADD_TO_CART", this.$store.state.customProduct);

      setTimeout(() => {
        btn.classList.remove("is-loading");
      }, 300);

      // reset qty
      // this.qty_selectedProduct = 1;
      // document.getElementById('qtyContainer_' + productId).innerText = 1;
    },

    fetchProductsFromCategory(categoryUrl) {
      // console.log('fetch products from category: ' + this.$route.params.categoryUrl);

      // this.$store.commit('SET_SELECTED_CATEGORY', parseInt(categoryParams.split('-').pop())); // set selected category bo!!!
      this.$store.commit("SET_LOADING_PRODUCTS", true);

      // axios.interceptors.request.use(
      //     config => {
      //         config.headers.authorization = 'Bearer ' + this.$store.state.user.user_id;
      //         return config;
      //     }
      // )

      axios
        .post(
          this.$store.state.apiUrl + "1/",
          {
            restaurantId: this.$store.state.company.user_id,
            categoryUrl: categoryUrl,
            action: "fetch-products-category",
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          // console.log(response.data);

          if (response.data.state === "success") {
            this.$store.commit(
              "SET_SELECTED_CATEGORY",
              response.data.categoryId
            );
            this.$store.commit("SET_PRODUCTS", response.data.products);

            // scroll into view (back button pressed)
            // setTimeout(() => {
            let categoryButton = document.getElementById(
              "category_" + response.data.categoryId
            );
            categoryButton.scrollIntoView();
            // }, 500);

            // error
          } else {
            this.$store.commit("SET_MODAL_CONFIRM", {
              opened: true,
              type: "error",
              msg: "EROARE 1594312576. Categoria nu exista.",
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$store.commit("SET_MODAL_CONFIRM", {
            opened: true,
            type: "error",
            msg: "EROARE 1594312576. Categoria nu exista.",
          });
        })
        .finally(() => {
          this.$store.commit("SET_LOADING_PRODUCTS", false);
        });
    },
  },

  filters: {
    dotToComma: (string) => string.replace(".", ","),
  },
  mounted() {
    // this.swipeListener();
    // console.log('url:' + this.$route.params.categoryUrl);
  },
  beforeRouteUpdate(to, from, next) {
    // console.log('to.params.categoryId: ' + to.params.categoryId);
    // console.log('to.path: ' + to.path);
    // console.log('restaurantId: ' + to.path);
    // this.$store.dispatch('fetchProducts', to.params.categoryId);
    this.fetchProductsFromCategory(to.params.categoryUrl);
    // this.fetchProductsFromCategory(to.params.categoryId, to.path);
    // this.fetchProductsFromCategory(to.params.categoryId);
    next();
  },
};
</script>

<style scoped>
/* products grid */
.masonry {
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
}
.item {
  display: inline-block;
  padding: 1em 1em 0 1em;
  margin: 0 0 1.5em 0;
  width: 100%;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  border-radius: 4px;
}
.item img {
  width: 100%;
  border-radius: 4px;
}
.product-image-container {
  min-height: 100px;
  /** spinin'loader */
  /* background-image: url('../../src/assets/loader.svg'); */
  /* background-repeat:no-repeat; */
  /* background-position: center center; */
}
.produs-indisponibil-badge {
  position: absolute;
  padding: 5px 10px;
  font-weight: bold;
}
#msg-no-products {
  text-align: center;
  margin-top: 30px;
}
footer {
  margin-top: 1em;
}

/* 1 column: smartphones vertical */
@media only screen and (max-width: 639px) {
  .masonry {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
/* 2 columns: smartphones horizontal / ipad vertical / ipad orizontal cu cart deschis */
@media only screen and (min-width: 640px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
/* 3 columns + cart: laptop 1280 */
@media only screen and (min-width: 1025px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
  #msg-no-products {
    text-align: left;
    margin: 30px 0 0 51%;
  }
}
/* 4 columns + cart: laptop 1366 */
@media only screen and (min-width: 1300px) {
  .masonry {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
/* 5 columns + cart: desktop 1680/1920 */
@media only screen and (min-width: 1650px) {
  .masonry {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
  }
}
/* end products grid */

/* edit qty btns */
.btnsQtyContainer {
  display: flex;
  border: 1px solid;
  border-radius: 4px;
  height: 40px;
}

.btnMinus,
.btnMinusElimina,
.btnPlus {
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
.btnQty {
  display: flex;
  align-items: center;
}

.productsGridContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.productsInCart {
  padding-bottom: 3px;
  font-size: 13px;
}
.cartPlusIcon {
  font-size: 20px;
}
.cartPlusIconContainer {
  margin: 0 7px;
}
.card {
  border-radius: 4px;
  width: 100%;
  /* height: 95%; */
  padding-bottom: 5px;
  /* width: 270px; */
}

.card-image {
  position: relative;
}
.buton-multiple-price {
  margin-top: 6px;
  padding: 6px 7px;
  border: 0;
}
.qty-shop-v0 {
  margin: 0 0 15px 0;
}
.qty-shop-v0 .button {
  padding: 14px !important;
  height: 30px;
  width: 30px;
}
.button-add-to-cart-v0 {
  padding: 3px 18px 0 16px;
}
.productCard {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  transition: 0.25s ease;
}
#addProductCard:hover {
  transform: none;
}
.unavailable-product {
  opacity: 0.5;
  border: 1px solid #f14668 !important;
}

.unavailable-badge {
  width: 200px;
  height: 35px;
  padding: 5px;
  text-align: center;
  position: absolute;
  background-color: #fff;
  top: 50%;
  margin-top: -17px;
  left: 50%;
  margin-left: -100px;
  font-size: 16px;
  font-weight: bold;
}

.product-name {
  padding: 10px;
  background-color: #fff;
}
.mobile-ingredients {
  margin: 0 15px;
  text-align: justify;
}
.mobile-product-photo {
  width: 100px;
  float: right;
  margin: 0 12px 5px 15px;
  transition: all 0.1s ease;
}
.mobile-product-photo-large {
  width: 93%;
}
.mobile-product-no-photo {
  height: 130px;
  display: flex;
  align-items: center;
  color: #ddd;
  justify-content: center;
  background-color: #f8f8f8;
  border-radius: 4px;
}
.card-footer {
  padding: 10px 0;
  align-content: center;
  position: relative;
}

.title-ingredients-container-no-hover {
  height: 100px;
  overflow: hidden !important;
  position: absolute;
  width: 100%;
}
.ingredients-shadow {
  position: absolute;
  bottom: 0;
  height: 35px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.editModalBkg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #00000070;
  /* background-color: rgba(0, 0, 0, 0.3); */
  /* cursor: pointer; */
}

.editModalBox {
  overflow: auto;
  background: #fff;
  box-shadow: 2px 2px 20px 1px;
  z-index: 99999 !important;
  margin: 10px;
  padding: 10px;
  border-radius: 3px;
  /* cursor: default; */
  width: 95%;
}
.modal {
  justify-content: start;
  padding: 10px;
  padding-top: 5%;
}
.modal-card {
  border-radius: 6px;
  padding-bottom: 60px;
}
.modal-card-body {
  border-radius: 6px;
}

#mobile-add-product-category a {
  border: 1px solid #fc0;
  padding: 2px 8px;
  border-radius: 4px;
}

.add-edit-buttons {
  padding: 3px 8px !important;
}

@media only screen and (max-width: 768px) {
  .btnMinus,
  .btnPlus {
    padding: 0 15px;
  }
  .btnMinusElimina {
    padding: 0 14px;
  }
  .tabs a {
    font-size: 13px !important;
  }
  .card-footer {
    border: 0;
    padding-top: 10px;
  }
  .productCard {
    margin: 10px 0;
  }
}
@media only screen and (min-width: 768px) {
  .card {
    width: 45%;
    margin: 10px;
  }
  .editModalBox {
    width: 400px;
    padding: 20px;
    margin: 5% 15px 15px 15px;
  }
  .card img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  /* .productCard:hover {
            transform: translate(0, -1px);
        } */
}

@media only screen and (min-width: 1024px) {
  .productCard {
    width: 225px;
  }
  .item:hover {
    box-shadow: 0 0.5em 1em 0.525em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
  }
  .unavailable-product:hover {
    opacity: 1;
  }
}
@media only screen and (min-width: 1367px) {
  .card {
    width: 235px;
  }
}
</style>
