// import store from ".";

export default {

	// universal
	STORE_MUTATION: (state, payload) => (state[payload.dataName] = payload.dataValue),

	SET_APP_URL: (state, payload) => (state.appUrl = payload),
	SET_API_URL: (state, payload) => (state.apiUrl = payload),
	SET_LOADING_FIRST_DATA: (state, payload) => (state.loadingFirstData = payload),

	SET_MUTATION: (state, payload) => (state.company[payload.field] = payload.value),

	SET_MODAL_CONFIRM: (state, payload) => (state.modalConfirm = payload),
	// SHOW_CLIENT_ORDERS_MODAL: (state, payload) => {
	
	// 	state.showClientOrdersModal = payload;
	// 	let localStorageOrders = JSON.parse(localStorage.getItem('orders'));
    //     state.pastOrders = localStorageOrders.filter(order => order.companyId == state.company.user_id).reverse(); // console.log(state.pastOrders);

	// },
	SET_PASTORDERS: (state, payload) => (state.pastOrders = payload),

	SET_LOADING_COMPANY: (state, payload) => (state.loadingCompany = payload),
	SET_COMPANY: (state, payload) => (state.company = payload),
	SET_WRONG_URL: (state, url) => (state.wrongUrl = url),

	SET_LOADING_ORDERS: (state, payload) => state.loadingOrders = payload,
	SET_ORDERS: (state, payload) => state.orders = payload,
	SET_NEW_ORDERS: (state, payload) => state.newOrders = payload,
	SET_SELECTED_ORDER_STATE: (state, payload) => state.selectedOrderState = parseInt(payload),

	SET_LOADING_CATEGORIES: (state, payload) => (state.loadingCategories = payload),
	// SET_CATEGORIES: (state, payload) => {
	//   if(payload === 0) {
	// 	state.categories = [];
	//   } else {
	// 	state.categories = payload;
	//   }
	// },
	SET_CATEGORIES: (state, payload) => { state.categories = payload },

	// SHOW_MODAL_ORDER_DATA: (state, payload) => state.showModalOrderData = payload,
	LOADING_MODAL_ORDER_DATA: (state, payload) => state.loadingModalOrderData = payload,
	MODAL_ORDER_DATA: (state, payload) => state.modalOrderData = payload,

	SET_LOADING_PRODUCTS: (state, payload) => (state.loadingProducts = payload),
	SET_PRODUCTS: (state, products) => (state.products = products), // varianta noua

	SET_LOADING_PRODUCT: (state, payload) => (state.loadingProduct = payload),	
	SET_PRODUCT: (state, product) => (state.product = product),
	SET_BTN_ADAUGA_ACTUALIZEAZA_COS: (state, payload) => (state.modifyProduct = payload),
	PRODUCT_MODAL: (state, payload) => (state.productModal = payload), // open close modal
	MODAL_ADMIN_PRODUCT_TOGGLER: (state, payload) => (state.modalAdminProduct = payload),

	// SETTINGS_MODAL_TOGGLER: (state, payload) => (state.settingsModal = payload),
	PRODUCTS_VIEW_TOGGLER: (state, payload) => {
		state.showProducts = payload;
		sessionStorage.setItem('showProducts', payload);
	},
	// MODAL_INFO_TOGGLER: (state) => (state.showModalInfo = !state.showModalInfo),
	// LOGIN_MODAL_TOGGLER: (state, payload) => (state.showModalLogin = !state.showModalLogin),

	CUSTOM_PRODUCT_PLUS_QTY: (state) => (state.customProduct.qty++),
	CUSTOM_PRODUCT_MINUS_QTY: (state) => (state.customProduct.qty--),
	
	// SET_EDIT_PRODUCT_MODAL: (state, payload) => (state.editProductModal = payload),
	SET_EDIT_PRODUCT_ID: (state, payload) => (state.editProductId = payload),

	SET_TOPPINGS: (state, payload) => (state.toppings = payload),
	SET_OPTIONS: (state, payload) => (state.options = payload),
	SET_ADD_CATEGORY: (state, payload) => (state.addCategory = payload),
	NEW_CATEGORY: (state, payload) => { 		
		state.categories = state.categories || [];
		state.categories.push(payload);
	 },
	SET_SELECTED_CATEGORY: (state, categoryId) => { 
	  let selectedCategory = state.categories.find(cat => cat.category_id == categoryId);
	  state.selectedCategory = selectedCategory;  // console.log(selectedCategory); 
	},
	SET_DEFAULT_SELECTED_CATEGORY: (state, payload) => (state.selectedCategory = payload),
	UPDATE_CATEGORY_ORDER: (state, payload) => {
	  let catToModify = state.categories.find(cat => cat.category_id == payload.category_id);
	  catToModify.category_order = payload.category_order;
	},
	// SHOW_BTNS_EDIT_CATEGORIES: (state, payload) => (state.showBtnsEditCategories = payload),
	// EDIT_CATEGORY: (state, payload) => (state.editCategory = payload),
	
	NEW_OPTION: (state, option) => { state.options.unshift(option); },

	MODAL_LOAD_PRODUCT_TO_MODIFY: (state, payload) => { // console.log(payload);
	 // load product from cart to modify

		state.customProduct.product_id = payload.product_id;
		state.customProduct.product_name = payload.product_name;
		state.customProduct.productPrice = payload.productPrice;
		state.customProduct.productPriceName = payload.productPriceName;
		state.customProduct.qty = payload.qty;
		state.customProduct.topping = payload.topping;
		state.customProduct.options = payload.options;
		state.customProduct.customProductPrice = payload.customProductPrice;
		state.customProduct.priceTotal = payload.priceTotal;
		state.customProduct.id = payload.id;
				
	},

	SET_CUSTOM_PRODUCT_PRICE: (state, payload) => (state.customProduct.productPrice = payload),
	SET_CUSTOM_PRODUCT_PRICE_NAME: (state, payload) => (state.customProduct.productPriceName = payload),

	SET_OPTIONS_ACTIONS_RESULT: (state, payload) => ( state.optionsActionsResult = payload ),

	SET_PRODUCTS_ACTIONS_RESULT: (state, payload) => (state.productsActionsResult = payload),

	RESET_CUSTOM_PRODUCT: (state) => {
		state.customProduct.product_id = '';
		state.customProduct.product_name = '';
		state.customProduct.productPrice = '';
		state.customProduct.productPriceName = '';
		state.customProduct.qty = 1;
		state.customProduct.topping = [];
		state.customProduct.options = [];
		state.customProduct.customProductPrice = '';
		state.customProduct.priceTotal = '';
		state.customProduct.id = '';		
	},

	REPLACE_CART: (state, payload) => state.cart = payload,

	ADD_TO_CART_EDITED_PRODUCT: (state) => (state.cart.push(JSON.stringify(state.customProduct))),

	ADD_TO_CART: (state, payload) => {

		// create array with products with same id:
		let cartSameItemsArray = state.cart.filter(obj => JSON.parse(obj).product_id === payload.product_id);
		
		// if is array -> compare in loop the product to add with each product/s already inserted in cart 
		if(cartSameItemsArray.length > 0) {

			// NEW PRODUCT TO ADD
			// 1. clone without reference
			let productToAdd = {...payload}; // console.log(productToAdd); return;
			

			// 2. prepare clone to compare -> delete particular reg. from elements elements from product: id/timestamp, qty, priceTotal
			delete productToAdd.id; // delete id = time added
			delete productToAdd.qty; // delete qty -> it may be different...
			delete productToAdd.priceTotal; // delete price if qty !== ...
			// console.log(`new product: ${JSON.stringify(payload)}`);
			

			// EXISTENT PRODUCT IN CART
			// array loop to find if there are a match with the new product to add
			for (let i in cartSameItemsArray) {
				
				// prepare for compare product in cart
				let productInCart = JSON.parse(cartSameItemsArray[i]);
				
				// save data to delete in new object
				var deletedFromProduct = { id:productInCart.id, qty:productInCart.qty };
				
				// delete data that can't match: id/time, qty, priceTotal (may differ because of qty)
				delete productInCart.id;
				delete productInCart.qty;
				delete productInCart.priceTotal;


				// check if already in cart
				if(JSON.stringify(productInCart) === JSON.stringify(productToAdd)) {

					var propertiesDeletedFromProduct = deletedFromProduct; // memorize the deleted properties of product that match 
					var cartItem = productInCart; // memorize the product that match
				
				} 

			};

			// if i have memorized data for one product that match
			if(propertiesDeletedFromProduct) {

				// new qty: qty in cart + qty from new product to insert
				let newQty = propertiesDeletedFromProduct.qty + payload.qty;
				
				// recalculate total price: customProductPrice from cart * new qty 
				let newPriceTotal = (newQty * cartItem.customProductPrice).toFixed(2); 

				// reassembles the product
				cartItem.id = propertiesDeletedFromProduct.id;
				cartItem.qty = newQty;
				cartItem.priceTotal = newPriceTotal;
				
				// delete the old cart item because is not reactive if only modify the values		
				state.cart = state.cart.filter(obj => JSON.parse(obj).id !== propertiesDeletedFromProduct.id);

				// reinsert the modified item to cart (it has the preserved the id/time of insertion for sort in same order)
				state.cart.push(JSON.stringify(cartItem));
								

			// product with same id but diversified customization
			} else {

				// add to cart
				state.cart.push(JSON.stringify(payload));
				
			}
			
		
		// no product with this id in cart => add to cart
		} else {

			state.cart.push(JSON.stringify(payload)); 

		}

		// this.$store.commit('RESET_CUSTOM_PRODUCT');
	
	},

	DELETE_CART_PRODUCT: (state, id) => {
	
		state.cart = state.cart.filter(obj => JSON.parse(obj).id !== id);
	},

	PLUS_QTY: (state, id) => {
	
		// find & store to memory the cart item with qty to modify
		let cartItem = state.cart.find(obj => JSON.parse(obj).id === id); // console.log(cartItem);
		
		// json to obj:
		cartItem = JSON.parse(cartItem);
		
		// increase qty
		cartItem.qty++;
		
		// new product total
		cartItem.priceTotal = (cartItem.qty * cartItem.customProductPrice).toFixed(2);
		
		// delete the cart item because is not reactive if modify a value and reinsert		
		state.cart = state.cart.filter(obj => JSON.parse(obj).id !== id);

		// reinsert the modified item to cart (it has the preserved time of insertion for sort in same order)
		state.cart.push(JSON.stringify(cartItem));


	},
	MINUS_QTY: (state, id) => { // console.log(id);
	

		// find & store to memory the cart item with qty to modify
		let cartItem = state.cart.find(obj => JSON.parse(obj).id === id); // console.log(cartItem);
		
		// json to obj:
		cartItem = JSON.parse(cartItem);
		
		// increase qty
		cartItem.qty--;
		
		// new product total
		cartItem.priceTotal = (cartItem.qty * cartItem.customProductPrice).toFixed(2);
		
		// delete the cart item because is not reactive if modify a value and reinsert		
		state.cart = state.cart.filter(obj => JSON.parse(obj).id !== id);

		// reinsert the modified item to cart (it has the preserved time of insertion for sort in same order)
		state.cart.push(JSON.stringify(cartItem));

	},
	// CART_MODAL_TOGGLER: (state) => state.cartModalOpened = !state.cartModalOpened,
	// CLOSE_CART_MODAL: (state) => state.cartModalOpened = false,
	// MODAL_SEND_ORDER_TOGGLER: (state) => state.modalSendOrderOpened = !state.modalSendOrderOpened,
	// MODAL_MENU_MOBILE_TOGGLER: (state) => state.modalMenuMobile = !state.modalMenuMobile,
	// MODAL_MENU_MOBILE_TOGGLER: (state, payload) => state.modalMenuMobile = payload,
	// CLOSE_MODAL_MENU_MOBILE: (state) => state.modalMenuMobile = false,
	SETTINGS_COLUMN_TOGGLER: (state) => state.settingsColumn = !state.settingsColumn,

	SET_LOADING_USER: (state, payload) => (state.loadingUser = payload),
	SET_USER: (state, payload) => (state.user = payload),
	SET_USER_URL: (state, payload) => (state.userUrl = payload),
	SET_AUTHENTICATED: (state, payload) => (state.authenticated = payload),

	SET_RESPONSE_MODAL_TEXT: (state, payload) => (state.modalResponseText = payload),
	
	RSP_SUCCESS: (state, obj) => {
	  // remove loading class
	  document.getElementById(obj.btnId).classList.remove('is-loading');
	  // success msg
	  // let rspContainer = document.getElementById(obj.containerId);
	  // rspContainer.innerHTML = '<span class="icon is-large has-text-primary"><i class="fas fa-2x fa-thumbs-up"></i></span>';
	  // setTimeout(() => { rspContainer.innerHTML = ''; }, 2000);
	},
	RSP_ERROR: (state, obj) => {
	  // remove loading class
	  document.getElementById(obj.btnId).classList.remove('is-loading');
	  // success msg
	  let rspContainer = document.getElementById(obj.containerId);
	  rspContainer.innerHTML = '<span class="icon is-large has-text-danger"><i class="fas fa-2x fa-thumbs-down"></i></span>';
	  setTimeout(() => { rspContainer.innerHTML = ''; }, 3000);
	},

	auth_request(state){ state.status = 'loading' },
	auth_success(state, token, user){
	  state.status = 'success'
	  state.token = token
	  state.user = user
	},
	auth_error(state){
	  state.status = 'error'
	},
	LOGOUT(state){
	  	state.userStatus = ''
	  // state.status = ''
	  // state.token = ''
	}
  }