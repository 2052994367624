<template>
	<div class="has-text-centered">

		<!-- new orders btn -->
		<!-- <div v-if="$store.state.newOrders" style="padding: 10px 0 20px 0">
			<a @click="$store.dispatch('fetchOrders', 1)" class="button is-danger">Ai primit {{$store.state.newOrders}} comenzi noi. 
				<span class="is-hidden-mobile"> Apasă aici pentru încărcarea lor</span>
			</a>
		</div> -->

		<!-- loading orders -->
		<div v-if="$store.state.loadingOrders">
			<br>
            <span class="icon has-text-primary">
                <i class="fas fa-2x fa-spinner fa-pulse"></i>
            </span>
		</div>

		<!-- orders -->
		<div v-else>
			<br class="is-hidden-mobile">
			<!-- no orders with selected status -->
			<div v-if="$store.state.orders.length < 1" class="has-text-danger">
				<br>Nu există comenzi în categoria selectată.
			</div>

			<!-- orders -->
			<div v-else style="display: grid;">

				<!-- table -->
				<div style="overflow: auto;" class="flipped">
					<div class="table-container is-size-7 has-text-weight-light">
						<table class="table is-stripped flipped" style="margin: 0 auto; width: 100%;">

							<!-- pagination -->
							<thead v-if="$store.state.ordersInState > $store.state.ordersOnPage">
								
								<!-- normal pagination < 8 pages -->
								<td colspan="9" v-if="$store.state.ordersNrPages < 8">
									<div class="buttons">
										<button class="button is-white has-text-weight-light">Pagini:</button>
										<button v-for="page in $store.state.ordersNrPages"
											:key="page"
											@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: page})"
											class="button is-small"
											:class="{'is-primary' : page === $store.state.ordersCurrentPage}"
										>
											{{page}}
										</button>
									</div>
								</td>

								<!-- reduced pagination pages > 7 -->
								<td colspan="9" v-else>
									<div class="is-flex">
										<button class="button is-white has-text-weight-light">Pagini:</button>
										
										<!-- page 1 -->
										<button
											@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: 1})"
											class="button is-small button-page"
											:class="{'is-primary' : $store.state.ordersCurrentPage === 1}"
										>1</button>

										<!-- ellipsis -->
										<span v-if="
											$store.state.ordersCurrentPage > 3											
											" class="pagination-ellipsis">
										&hellip;</span>

										<!-- pages -->
										<div v-for="page in $store.state.ordersNrPages" :key="page">
											<button
												v-if="(
													page !== 1
													&&
													page < ($store.state.ordersCurrentPage + 2)
													&&
													page > ($store.state.ordersCurrentPage - 2)
													&&
													page !== $store.state.ordersNrPages
													)"
												@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: page})"
												class="button is-small button-page"
												:class="{'is-primary' : page === $store.state.ordersCurrentPage}"
											>{{page}}</button>
										</div>

										<!-- ellipsis -->
										<span v-if="$store.state.ordersCurrentPage < $store.state.ordersNrPages - 2" class="pagination-ellipsis">
										&hellip;</span>

										<!-- last button -->
										<button
											@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: $store.state.ordersNrPages})"
											class="button is-small button-page"
											:class="{'is-primary' : $store.state.ordersCurrentPage === $store.state.ordersNrPages}"
										>{{$store.state.ordersNrPages}}</button>

									</div>
								</td>
							</thead>

							<!-- table head -->
							<thead>
								<tr>
									<th>Nr.</th>
									<th>DATA</th>
									<th>NUME, TELEFON</th>
									<th>ADRESA</th>
									<th>EMAIL</th>
									<th>OBSERVAȚII</th>
									<th>STARE COMANDĂ</th>
									<th>DETALII COMANDĂ</th>
								</tr>
							</thead>

							<!-- orders loop -->
							<tr v-for="order in $store.state.orders" :key="order.order_id">
								<!-- id comanda -->
								<td>{{order.comanda_id}}</td>
								<!-- data -->
								<td class="nowrap">{{order.order_date | formatDate}}</td>
								
								<td class="nowrap">
									<div>
										<!-- nume client -->	
										{{order.nume}},
										
										<!-- telefon -->
										<a :href="'tel:' + order.telefon" class="has-text-success nowrap">
											<span class="icon is-small">
												<i class="fas fa-phone-volume"></i>
											</span>
											{{order.telefon | phoneNumber}}
										</a>
									</div>
									<div class="is-size-7 has-text-grey-light">
										<span v-if="order.client_nr_comenzi > 1">
											(comenzi trimise: {{order.client_nr_comenzi}}, total: {{order.client_valoare_comenzi}})
										</span>
										<span v-else class="has-text-red-light">
											(prima comandă a acestui client)
										</span>
									</div>
								</td>
								<!-- adresa -->
								<td class="text-container">
									<!-- open google maps -->
									<span class="icon has-text-success">
										<!-- <i class="fas fa-location-arrow"></i> -->
										<i class="fas fa-directions"></i>
									</span>
									<a :href="'https://maps.google.com?q=' + getAddressLink(order.adresa, order.bloc, order.oras)" target="_blank" class="goog-link">
										<!-- strada -->
										{{order.adresa}}
										<!-- bloc -->
										<span v-if="order.bloc">, bloc: {{order.bloc}}</span>
										<!-- scara -->
										<span v-if="order.scara">, scara: {{order.scara}}</span>
										<!-- apartament -->
										<span v-if="order.ap">, ap.: {{order.ap}}</span>
										<!-- oras -->
										<span>, {{order.oras}}</span>
									</a>
								</td>
								<td>
									<!-- email -->
									<span v-if="order.email">{{order.email}}</span>
									<span v-else class="has-text-grey-lighter">(necompletat)</span>
								</td>
								<td>
									<!-- observatii -->
									<div v-if="order.observatii" :class="order.observatii.length > 50 ? 'text-container' : 'nowrap'">
										{{order.observatii}}
									</div>
									<span v-else class="has-text-grey-lighter">
										(necompletat)
									</span>
								</td>
								<td>
									<!-- status -->
									<div class="select is-small" :id="'select-id-' + order.order_id">
										<select @change="$store.dispatch('changeOrderState', {orderId : order.order_id, currentState : order.order_state, page: $store.state.ordersCurrentPage})">
										<!-- <select @change="changeOrderState(order.order_id, order.order_state)"> -->
											<option value="1" :selected = "order.order_state == 1">Comandă nouă</option>
											<option value="2" :selected = "order.order_state == 2">Comandă în lucru</option>
											<option value="3" :selected = "order.order_state == 3">Comandă în livrare</option>
											<option value="4" :selected = "order.order_state == 4">Comandă livrată</option>
											<option value="5" :selected = "order.order_state == 5">Comandă anulată</option>
										</select>
									</div>
								</td>
								<td>
									<!-- detalii comanda -->
									<a @click="fetchOrderData(order.order_id)" class="button is-small is-primary is-outlined">Deschide comanda</a>
								</td>
							</tr>

							<!-- bottom pagination -->
							<tr v-if="$store.state.ordersInState > $store.state.ordersOnPage">
								
								<!-- normal pagination pages < 8 -->
								<td colspan="9" v-if="$store.state.ordersNrPages < 8">
									<div class="buttons">
										<button class="button is-white has-text-weight-light">Pagini:</button>
										<button v-for="page in $store.state.ordersNrPages"
											:key="page"
											@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: page})"
											class="button is-small"
											:class="{'is-primary' : page === $store.state.ordersCurrentPage}"
										>
											{{page}}
										</button>
									</div>
								</td>

								<!-- reduced pagination pages > 7 -->
								<td colspan="9" v-else>
									<div class="is-flex">
										<button class="button is-white has-text-weight-light">Pagini:</button>
										
										<!-- page 1 -->
										<button
											@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: 1})"
											class="button is-small button-page"
											:class="{'is-primary' : $store.state.ordersCurrentPage === 1}"
										>1</button>

										<!-- ellipsis -->
										<span v-if="
											$store.state.ordersCurrentPage > 3											
											" class="pagination-ellipsis">
										&hellip;</span>

										<!-- pages -->
										<div v-for="page in $store.state.ordersNrPages" :key="page">
											<button
												v-if="(
													page !== 1
													&&
													page < ($store.state.ordersCurrentPage + 2)
													&&
													page > ($store.state.ordersCurrentPage - 2)
													&&
													page !== $store.state.ordersNrPages
													)"
												@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: page})"
												class="button is-small button-page"
												:class="{'is-primary' : page === $store.state.ordersCurrentPage}"
											>{{page}}</button>
										</div>

										<!-- ellipsis -->
										<span v-if="$store.state.ordersCurrentPage < $store.state.ordersNrPages - 2" class="pagination-ellipsis">
										&hellip;</span>

										<!-- last button -->
										<button
											@click="$store.dispatch('fetchOrders', { orderStateId: $store.state.selectedOrderState, page: $store.state.ordersNrPages})"
											class="button is-small button-page"
											:class="{'is-primary' : $store.state.ordersCurrentPage === $store.state.ordersNrPages}"
										>{{$store.state.ordersNrPages}}</button>

									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>

		<ModalConfirm/>
  	</div>
</template>

<script>
import axios from 'axios';
import ModalConfirm from '@/components/ModalConfirm.vue';
// import ModalOrder from '@/components/admin/ModalOrder.vue';

export default {
	name: 'Comenzi',
	components: {
		ModalConfirm
		// ModalOrder
	},
	data() {
		return {
			// comanda: {
			// 	comandaId: '',
			// 	nume: '',
			// 	dataTrimitere: ''
			// }
		}
	},
	methods: {
		getAddressLink(adresa=0, bloc=0, oras=0) {

			let link;
			if(adresa != 0) { link = adresa; }
			if(bloc != 0) { link = link + '+bloc+' + bloc; }
			if(oras != 0) { link = link + '+' + oras + '+Romania'; }

			return link;
			// return encodeURIComponent(link);
		},
		fetchOrderData(orderId) {
		// fetchOrderData(orderId, comandaId, nume, dataTrimitere) {


			// this.comanda.comandaId = comandaId;
			// this.comanda.nume = nume;
			// this.comanda.dataTrimitere = dataTrimitere;

			this.$router.push('/detalii-comanda/' + orderId);

			this.$store.commit('LOADING_MODAL_ORDER_DATA', true);
			
			// date existente
			// this.$store.state.modalOrderData = this.$store.state.orders.find(item => item.order_id == orderId);
			
			axios
			.post(this.$store.state.apiUrl,
				{orderId, action: 'fetch-order-data'},
				{withCredentials: true})
			.then(response => { // console.log(response.data);

				if(response.data.state === 'success') {
					this.$store.commit('MODAL_ORDER_DATA', response.data);
				} else {
					this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE 1590395101: Am pierdut conexiunea cu server-ul. Te rog reîncarcă pagina și încearcă din nou.'});
					// close modal
					// this.$store.commit('SHOW_MODAL_ORDER_DATA', false);
				}				
			})
			.catch(error => {
				// display error
				this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE 1590395871: Server-ul nu a transmis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.'});
				// this.$store.commit('SHOW_MODAL_ORDER_DATA', false); // close modal
			})
			.finally(() => {
				// remove loading
				this.$store.commit('LOADING_MODAL_ORDER_DATA', false);
			})

					
		},
		// deschideComanda(order_id) {

		// 	this.$router.push('/detalii-comanda');
		// 	this.fetchOrderData(order_id);
		// },
		// deschideComanda(order_id, comanda_id, nume, order_date) {

		// 	this.$router.push('/detalii-comanda');
		// 	this.fetchOrderData(order_id, comanda_id, nume, order_date);
		// },

		// changeOrderState(orderId, currentState) {

		// 	document.getElementById('select-id-' + orderId).classList.add('is-loading');
		// 	let newState = event.target.value;

		// 	axios
        //     .post(this.$store.state.apiUrl,
        //         {
        //             orderId: orderId,
        //             newState: newState,
        //             action: 'change-order-state'
        //         },
        //         {withCredentials: true})
		// 	.then(response => { // console.log(response.data); // console.log(response.config);
			
		// 		// success
		// 		if(response.data.state === 'success') {

		// 			let newStateName;

		// 			switch(newState) {
		// 				case '1':
		// 					newStateName = 'COMENZI NOI';
		// 					break;
		// 				case '2':
		// 					newStateName = 'COMENZI ÎN LUCRU';
		// 					break;
		// 				case '3':
		// 					newStateName = 'COMENZI ÎN LIVRARE';
		// 					break;
		// 				case '4':
		// 					newStateName = 'COMENZI LIVRATE';
		// 					break;
		// 				case '5':
		// 					newStateName = 'COMENZI ANULATE';
		// 					break;
		// 			}

		// 			this.$store.commit('SET_MODAL_CONFIRM', {type: 'success', msg: `Comanda a fost mutată în ${newStateName}`});

		// 			// reload current
		// 			this.$store.dispatch('fetchOrders', currentState);

		// 		// error
		// 		} else {

		// 			this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: Operațiunea nu a putut fi efectuată. Te rog reîncarcă pagina și încearcă din nou.'});

		// 		}
        //     })
        //     .catch(error => {
        //         this.$store.commit('SET_MODAL_CONFIRM', {type: 'error', msg: 'EROARE: Operațiunea nu a putut fi efectuată. Te rog reîncarcă pagina și încearcă din nou.'});
        //     })
        //     .finally(() => {
		// 			// remove loading
		// 			// document.getElementById('select-id-' + orderId).classList.remove('is-loading');
        //         }
        //     );
			
		// }
	},
	filters: {
		phoneNumber: string => `${string.substr(0, 4)} ${string.substr(4, 3)} ${string.substr(7, 5)}`,
		formatDate: string => { 

			let d = new Date(string * 1000);
			return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}, ${d.getHours()}:${('0' + d.getMinutes()).slice(-2)}`;
		}
	},
	created() {
		this.$store.dispatch('fetchOrders', { orderStateId: 1, page: 1}); // order_state: 1 (comanda noua)
	}
	  
}
</script>

<style scoped>
	.goog-link {
		color: #333;
	}
	.goog-link:hover {
		text-decoration: underline;
	}
	.has-text-red-light {
		color: #ffaaaa;
	}
	.button-page {
		margin: 2px;
	}
	/* .flipped { */
		/* transform:rotateX(180deg); */
		/* -ms-transform:rotateX(180deg); IE 9 */
		/* -webkit-transform:rotateX(180deg); Safari and Chrome */
	/* } */
	tr:hover {
		background-color: #f9f9f9;
	}
	td {
		vertical-align: middle !important;
	}
	th {
		text-align: center !important;
	}
	.text-container {
		max-width: 300px; 
		min-width: 200px;
	}
	@media only screen and (min-width: 1024px) {
		.table-container {
			font-weight: normal !important;
		}
	}
</style>
