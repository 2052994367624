import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";

// axios.interceptors.request.use(
// 	config => {
// 		config.headers.authorization = 'Bearer ' + store.state.company.user_id;
// 		return config;
// 	}
// )

export default {
	// auth user data
	async fetchUser({ commit }) {
		// console.log('fetchUser');

		commit("SET_LOADING_USER", true);

		try {
			const response = await axios.post(store.state.apiUrl, { action: "fetch-auth-user" }, { withCredentials: true }); // console.log(response.data);

			if (response.data.state === "success") {
				commit("SET_USER", response.data.user);
			} else {
				commit("SET_MODAL_CONFIRM", {
					type: "error",
					msg: "EROARE 1591118991: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
				});
			}
		} catch (error) {
			commit("SET_MODAL_CONFIRM", {
				type: "error",
				msg: "EROARE 1591118999: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
			});
		} finally {
			commit("SET_LOADING_USER", false);
		}
	},

	// first load new
	async firstLoad({ commit }, userUrls) {
		// start loading
		commit("SET_LOADING_FIRST_DATA", true);

		// console.log(store.state.userUrl);

		try {
			const response = await axios.post(store.state.apiUrl + "1/", { action: "firstLoad", userUrl: store.state.userUrl }, { withCredentials: true });
			// console.log(response.data);

			// success
			if (response.data.state === "success") {
				// no url in db
				if (response.data.noUrl === true) {
					// console.log('no url');
					commit("SET_WRONG_URL", store.state.userUrl);
				}

				// if user authenticated
				if (response.data.user) {
					commit("SET_USER", response.data.user);
				}

				// authenticated
				commit("SET_AUTHENTICATED", response.data.authenticated);

				// company (shop)
				if (response.data.company) {
					commit("SET_COMPANY", response.data.company);
					document.title = response.data.company.company; // page title
				}

				// categories
				commit("SET_CATEGORIES", response.data.categories);

				// /comenzi but no categories:
				if (window.location.pathname === "/comenzi") {
					router.push("/");
				}

				if (response.data.selectedCategory) {
					// selected category
					commit("SET_DEFAULT_SELECTED_CATEGORY", response.data.selectedCategory);

					// route default category: Error: Avoided redundant navigation to current location: "/paste"
					if (window.location.pathname !== "/" + response.data.selectedCategory.category_url) {
						router.replace(response.data.selectedCategory.category_url);
					}
				}

				// no categories: push / close product/category modal gestiune
				if (response.data.categories === null && window.location.pathname === "/gestiune") {
					router.push("/");
				}

				// products from default category
				commit("SET_PRODUCTS", response.data.products); // console.log(response.data.products);

				// error
			} else {
				commit("SET_MODAL_CONFIRM", {
					type: "error",
					msg: "EROARE 1591118647: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
				});
			}

			// error
		} catch (error) {
			console.log(error);

			commit("SET_MODAL_CONFIRM", {
				type: "error",
				msg: "EROARE 1591118648: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
			});

			// stop loading
		} finally {
			commit("SET_LOADING_FIRST_DATA", false);
		}
	},

	// schimba status comanda (comenzi, modalOrder)
	changeOrderState({ commit }, payload) {
		let clickedElement = event.target;
		clickedElement.parentElement.classList.add("is-loading"); // loading
		let newState = clickedElement.value; // new state

		axios
			.post(
				store.state.apiUrl,
				{
					orderId: payload.orderId,
					newState: newState,
					action: "change-order-state",
				},
				{ withCredentials: true }
			)
			.then((response) => {
				// console.log(response.data); // console.log(response.config);

				// success
				if (response.data.state === "success") {
					let newStateName;

					switch (newState) {
						case "1":
							newStateName = "COMENZI NOI";
							break;
						case "2":
							newStateName = "COMENZI ÎN LUCRU";
							break;
						case "3":
							newStateName = "COMENZI ÎN LIVRARE";
							break;
						case "4":
							newStateName = "COMENZI LIVRATE";
							break;
						case "5":
							newStateName = "COMENZI ANULATE";
							break;
					}

					store.commit("SET_MODAL_CONFIRM", {
						type: "success",
						msg: `Comanda a fost mutată în ${newStateName}`,
					});

					// reload current
					store.dispatch("fetchOrders", {
						orderStateId: payload.currentState,
						page: payload.page,
					});

					// error
				} else {
					store.commit("SET_MODAL_CONFIRM", {
						type: "error",
						msg: "EROARE: Operațiunea nu a putut fi efectuată. Te rog reîncarcă pagina și încearcă din nou.",
					});
				}
			})
			.catch((error) => {
				store.commit("SET_MODAL_CONFIRM", {
					type: "error",
					msg: "EROARE: Operațiunea nu a putut fi efectuată. Te rog reîncarcă pagina și încearcă din nou.",
				});
			})
			.finally(() => {
				if (router.currentRoute.path !== "/comenzi") {
					router.replace("/comenzi"); // schimb stare comanda din modal detalii comanda
				}
			});
	},

	// company data
	async company({ commit }, userUrl) {
		commit("SET_LOADING_COMPANY", true);

		const response = await axios.get(store.state.apiUrl + "get" + userUrl + "/company", { withCredentials: true }); // (url are slash inainte)
		// console.log(response.data);

		// set company data
		if (response.data.status === "success") {
			commit("SET_COMPANY", response.data.company);

			// error: no url/company
		} else {
			commit("SET_WRONG_URL", response.data);
		}

		commit("SET_LOADING_COMPANY", false);
	},

	/**
	 * FETCH CATEGORIES & CATEGORY.PRODUCTS
	 */

	// home page data: categories list for menu & first category/automatic selected category products

	// ???????
	async fetchCategoriesWithProducts({ commit }, userUrl) {
		// make ajax call

		commit("SET_LOADING_CATEGORIES", true);
		commit("SET_LOADING_PRODUCTS", true);

		// axios call
		const response = await axios.get(store.state.apiUrl + "get" + userUrl + "/fetch-categories-and-first-products"); // console.log(response);

		// categories
		if (response.data.categories !== 0) {
			commit("SET_CATEGORIES", response.data.categories);
		}
		commit("SET_LOADING_CATEGORIES", false);

		// products
		if (response.data.products !== 0) {
			commit("SET_PRODUCTS", response.data.products);
		}
		commit("SET_LOADING_PRODUCTS", false);

		// selected category for display products
		commit("SET_SELECTED_CATEGORY", response.data.selectedCategory); // update state.selectedCategory
	},

	// fetch categories
	async fetchCategories({ commit }, userId) {
		// make ajax call

		commit("SET_LOADING_CATEGORIES", true);
		// commit('SET_LOADING_PRODUCTS', true);

		try {
			// axios call
			const response = await axios.post(store.state.apiUrl + "1/", { userId, action: "fetch-categories" }, { withCredentials: true }); // console.log(response.data);

			// categories
			if (response.data.state === "success") {
				commit("SET_CATEGORIES", response.data.categories);
			} else {
				commit("SET_MODAL_CONFIRM", {
					type: "error",
					msg: "EROARE 1589454833: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
				});
			}
		} catch (error) {
			commit("SET_MODAL_CONFIRM", {
				type: "error",
				msg: "EROARE 1589453974: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
			});
		} finally {
			// stop loading
			commit("SET_LOADING_CATEGORIES", false);
			if (store.state.selectedCategory.category_id) {
				commit("SET_SELECTED_CATEGORY", store.state.selectedCategory.category_id); // update state.selectedCategory
			}
		}
	},

	// FETCH ORDERS BY STATUS
	async fetchOrders({ commit }, payload) {
		// payload = orderStateId, page
		// async fetchOrders({commit}, orderStateId, page = 1) {

		// loading
		commit("SET_LOADING_ORDERS", true);

		// set order state id
		commit("SET_SELECTED_ORDER_STATE", payload.orderStateId);

		// store this page number
		commit("STORE_MUTATION", {
			dataName: "ordersCurrentPage",
			dataValue: payload.page,
		});

		try {
			const response = await axios.post(
				store.state.apiUrl,
				{
					orderStateId: payload.orderStateId,
					page: payload.page,
					ordersOnPage: store.state.ordersOnPage,
					action: "fetch-orders",
				},
				{ withCredentials: true }
			); // console.log(response.data);

			// success
			if (response.data.state === "success") {
				// load orders (max nr on page)
				commit("SET_ORDERS", response.data.orders);

				// nr. total orders with this state
				commit("STORE_MUTATION", {
					dataName: "ordersInState",
					dataValue: response.data.ordersInState,
				});

				// update nr total new Orders
				if (parseInt(payload.orderStateId) === 1) {
					commit("STORE_MUTATION", {
						dataName: "newOrders",
						dataValue: response.data.ordersInState,
					});
				}

				// if orders with this state
				if (response.data.ordersInState > 0) {
					// calcul nr. pagini necesare pentru a afisa comenzile with this state
					let order_NrPages = Math.ceil(response.data.ordersInState / store.state.ordersOnPage);

					// nr de pagini trimis of this state a fost modificat (mai mic) - ultimul order din pagina a fost mutat: reload data for last page
					if (payload.page > order_NrPages) {
						// reload current
						store.dispatch("fetchOrders", {
							orderStateId: payload.orderStateId,
							page: order_NrPages,
						});
					}

					// nr pagini orders with this state
					commit("STORE_MUTATION", {
						dataName: "ordersNrPages",
						dataValue: order_NrPages,
					});
				}

				// error
			} else {
				commit("SET_MODAL_CONFIRM", {
					type: "error",
					msg: "EROARE: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
				});
			}
		} catch (error) {
			commit("SET_MODAL_CONFIRM", {
				type: "error",
				msg: "EROARE 1589453737: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
			});
		} finally {
			// stop loading
			commit("SET_LOADING_ORDERS", false);
		}
	},

	// fetch new orders (NR.)
	// async fetchNewOrders({commit}) {

	// 	const response = await axios.post(store.state.apiUrl, {action: 'fetch-new-orders'}, {withCredentials: true}); // console.log(response.data);
	// 	// success
	// 	if(response.data.state === 'success') {
	// 		commit('SET_NEW_ORDERS', response.data.orders);
	// 	}

	// },

	// fetch new orders number
	async fetchNewOrdersNr({ commit }) {
		const response = await axios.post(store.state.apiUrl, { action: "fetch-new-orders-nr" }, { withCredentials: true }); // console.log(response.data);
		// success
		if (response.data.state === "success") {
			commit("SET_NEW_ORDERS", response.data.orders);
		}
	},

	// FETCH PRODUCTS FROM CATEGORY
	async fetchProducts({ commit }, categoryId) {
		commit("SET_SELECTED_CATEGORY", categoryId);
		commit("SET_LOADING_PRODUCTS", true); // show spinner & hide old data

		try {
			const response = await axios.post(
				store.state.apiUrl + "1/",
				{
					categoryId: categoryId,
					action: "fetch-products-from-category",
				},
				{
					withCredentials: true,
				}
			); // console.log(response.data);
			// success
			if (response.data.state === "success") {
				commit("SET_PRODUCTS", response.data.products); // set products

				// error
			} else {
				commit("SET_MODAL_CONFIRM", {
					type: "error",
					msg: "EROARE 1593596782: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
				});
			}
		} catch (error) {
			commit("SET_MODAL_CONFIRM", {
				type: "error",
				msg: "EROARE 1593596783: Server-ul nu a trimis niciun răspuns. Te rog reîncarcă pagina și încearcă din nou.",
			});
		} finally {
			// stop loading
			commit("SET_LOADING_PRODUCTS", false); // hide spinner & show data
		}
	},

	scrollEnd() {
		if (document.getElementById("scroll-div")) {
			let scrollDiv = document.getElementById("scroll-div");
			scrollDiv.scrollLeft += 2000;
		}
	},

	/**
	 * adauga categorie
	 */
	addCategory({ commit }, payload) {
		// console.log(payload);

		if (payload.trim() === "") {
			return;
		}

		let btn = event.target;
		btn.classList.add("is-loading");
		commit("SET_LOADING_CATEGORIES", true);

		axios
			.post(
				store.state.appUrl + "/api/",
				{
					numeCategorie: payload,
					action: "addCategory",
				},
				{ withCredentials: true }
			)

			.then((response) => {
				// console.log(response.data);

				if (response.data.state === "success") {
					// add category => set selected category
					if (response.data.inserted_category) {
						commit("NEW_CATEGORY", response.data.inserted_category);
						commit("SET_DEFAULT_SELECTED_CATEGORY", response.data.inserted_category);
						// this.$store.dispatch('fetchCategories', this.$store.state.user.user_id);
						store.dispatch("fetchProducts", response.data.inserted_category.category_id);
						// this.$store.commit('SET_ADD_CATEGORY', false);
						commit("SET_MODAL_CONFIRM", {
							opened: true,
							type: "success",
							msg: "Categoria a fost inserată cu succes.",
						});
						// scrollIntoView inserted category
						setTimeout(() => {
							let insertedCategoryElement = document.getElementById("category_" + response.data.inserted_category.category_id);
							insertedCategoryElement.scrollIntoView();
						}, 500);
					}

					// error
				} else {
					commit("SET_MODAL_CONFIRM", {
						opened: true,
						type: "error",
						msg: "EROARE 1593680308. Categoria nu a putut fi salvată în baza de date. Te rog reîncarcă pagina și încearcă din nou.",
					});
				}
			})
			.catch((error) => {
				// console.log(error);
				commit("SET_MODAL_CONFIRM", {
					opened: true,
					type: "error",
					msg: "EROARE 1593680309. Categoria nu a putut fi salvată în baza de date. Te rog reîncarcă pagina și încearcă din nou.",
				});
			})
			.finally(() => {
				// this.numeCategorie = '';
				commit("SET_LOADING_CATEGORIES", false);
				// new category url
				router.replace(store.state.selectedCategory.category_url);
				// this.$router.replace(this.$store.getters.getAppRoute);
			});
	},

	// goBack() {
	// 	window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
	// }

	/**
	 *
	 * END CATEGORIES
	 *
	 *
	 *
	 * TOPPING
	 */
	// ADD TOPPING
	// async addOption({ commit }, data) {
	// 	// post request
	// 	const response = await axios.post(store.state.apiUrl, {data, action: 'addOption' }); // console.log(response.data);
	// 	// success
	// 	if(response.data.option_name) {
	// 		commit('RSP_SUCCESS', { btnId: 'addOptionBtn', containerId: 'newOptionMsg' });
	// 		commit('NEW_OPTION', response.data);
	// 	// error
	// 	} else {
	// 		commit('RSP_ERROR', { btnId: 'addOptionBtn', containerId: 'newOptionMsg' });
	// 	}

	// },
	// FETCH OPTIONS
	// async fetchToppings({ commit }) {
	// 	// post request
	// 	const response = await axios.get(store.state.apiUrl + 'get/fetch-toppings');
	// 	// success
	// 	if(response.data.options !== 0) {
	// 		commit('SET_TOPPINGS', response.data.toppings);
	// 	// error
	// 	} else {
	// 		// TODO
	// 	}
	// },

	/**
	 * END PRODUCTS TOPPING
	 *
	 *
	 *
	 *
	 * OPTIUNI
	 */
	// async fetchOptions({commit}) {

	// 	// post request
	// 	const response = await axios.get(store.state.apiUrl + 'get/fetch-options'); // console.log(response.data);
	// 	// success
	// 	if(response.data.options) {
	// 		commit('SET_OPTIONS', response.data.options);
	// 	// error
	// 	} else {
	// 		// TODO
	// 	}

	// },
	/**
	 * END OPTIUNI
	 *
	 *
	 *
	 *
	 * PRODUCTS
	 */

	// add product
	// async addProduct({commit}, data) {
	// 	// post request
	// 	const response = await axios.post(store.state.apiUrl, {
	// 		data,
	// 		action: 'addProduct'
	// 	}); // console.log(response);

	// 	// success
	// 	if(response.status === 200 && response.data.name) {

	// 		// reload products from this category
	// 		// this.fetchProducts({commit}, store.state.selectedCategory.category_id);

	// 		// msg action result
	// 		commit('SET_PRODUCTS_ACTIONS_RESULT', 'success');
	// 		setTimeout(() => { commit('SET_PRODUCTS_ACTIONS_RESULT', ''); }, 1000); // reset msg

	// 		// console.log('actions: am inserat');

	// 	// error
	// 	} else {

	// 		commit('SET_PRODUCTS_ACTIONS_RESULT', 'error');
	// 		setTimeout(() => { commit('SET_PRODUCTS_ACTIONS_RESULT', ''); }, 1000); // reset msg

	// 	}

	// },

	// addToCart({commit}, payload) {
	// 	commit('ADD_TO_CART', payload);
	// },
	// plusQty_A({commit}, payload) {
	// 	console.log('increase from actions' + payload);

	// },
	// minusQty_A({commit}, payload) {
	// 	console.log('decrease from actions');

	// },
	// fetch products from category
	// async fetchProducts({commit}, categoryId) {

	// 	console.log('action fetch/reload products from category ' + categoryId);

	// },
	/**
	 * END PRODUCTS
	 *
	 *
	 * .......
	 */

	// async login({commit}, userData) { // object user -> destructured: user.email & user.password
	// 	return new Promise((resolve, reject) => {
	// 		axios({url: store.state.apiUrl, data: {userData, action: 'login'}, method: 'POST' })
	// 		.then(response => {
	// 			const userToken = response.data.userToken;
	// 			const userData = response.data.userData;
	// 			localStorage.setItem('userToken', userToken);
	// 			commit('auth_success', userToken, userData);
	// 			resolve(response);
	// 			console.log(response);
	// 		})
	// 		.catch(error => {
	// 			commit('auth_error');
	// 			localStorage.removeItem('token');
	// 			reject(err);
	// 			console.log('error');
	// 		})
	// 	});
	// 	// console.log(`credenziali: user->${userData.user} și password->${userData.password}`);
	// },
	// async logout({commit}) {
	// 		return new Promise((resolve, reject) => {
	// 		commit('LOGOUT')
	// 		localStorage.removeItem('token')
	// 		// delete axios.defaults.headers.common['Authorization']
	// 		resolve()
	// 	})
	// },
	// async testLogin({commit}) {
	// 	setTimeout(() => {
	// 		let authState = 'authenticated';
	// 		commit('SET_USER_STATUS', authState);
	// 	}, 0);
	// }
};
